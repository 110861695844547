import Http from "../utils/Http";
import { omit } from "lodash";

//计划列表
export function getPlanList(data) {
  const result = Http({
    url: "/share-wifi/plan",
    method: "GET",
    data,
  });
  return result;
}

//删除计划
export function planDel(data) {
  const result = Http({
    url: "/share-wifi/plan",
    method: "DELETE",
    data,
  });
  return result;
}
//编辑计划
export function planEdit(data) {
  const result = Http({
    url: "/share-wifi/plan" + data._url,
    method: "PUT",
    data: omit(data, "_url"),
  });
  return result;
}

//添加计划
export function planAdd(data) {
  const result = Http({
    url: "/share-wifi/plan",
    method: "POST",
    data,
  });
  return result;
}

//计划详情
export function planDetail(data) {
  const result = Http({
    url: "/share-wifi/plan" + data._url,
    method: "GET",
    data: omit(data, "_url"),
  });
  return result;
}
//计划详情统计
export function planStat(data) {
  const result = Http({
    url: "/share-wifi/plan-stat" + data._url,
    method: "GET",
    data: omit(data, "_url"),
  });
  return result;
}

export function planStatPK(data) {
  const result = Http({
    url: "/share-wifi/plan_stat_pk",
    method: "GET",
    data,
  });
  return result;
}

export function getPlans(data) {
  const result = Http({
    url: "/share-wifi/plans",
    method: "GET",
    data,
  });
  return result;
}
