import React, { useState } from "react";
import { Statistic, Card } from "antd";
import { useRequest } from "ahooks";
import { indexPortal } from "@/api";
import { useHistory } from "react-router-dom";
import { formatMoney } from "@/utils";

function Overview(props) {
  const history = useHistory();
  const [data, setData] = useState({});
  const { loading } = useRequest(
    () => {
      return indexPortal();
    },
    {
      onSuccess: (res) => {
        console.log("广告统计", res);
        const result = res.data.data;
        setData(result);
      },
      onError: (error) => {
        console.log("广告统计", error);
      },
    }
  );
  return (
    <Card title="广告统计" loading={loading}>
      <div className="overview">
        <Statistic
          title="投放中"
          value={data.portal_active}
          formatter={(value) => (
            <div
              onClick={() => {
                history.push("Plan?status=2");
              }}
              className="count"
            >
              {value}
            </div>
          )}
        />
        <Statistic
          title="已完成"
          value={data.portal_finished}
          formatter={(value) => (
            <div
              onClick={() => {
                history.push("Plan?status=3");
              }}
              className="count"
            >
              {formatMoney(value)}
            </div>
          )}
        />
        <Statistic
          title="下发路由器数量"
          value={data.devices}
          formatter={(value) => (
            <div>{formatMoney(value)}</div>
          )}
        />
        <Statistic
          title="投放国家数量"
          value={data.country}
          formatter={(value) => (
            <div>{formatMoney(value)}</div>
          )}
        />
        <Statistic
          title="已创建Portal"
          value={data.portals}
          formatter={(value) => (
            <div
              onClick={() => {
                history.push("/Portal/PortalList");
              }}
              className="count"
            >
              {formatMoney(value)}
            </div>
          )}
        />
      </div>
    </Card>
  );
}

export default Overview;
