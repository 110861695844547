import React from "react";
import { Form, Input } from "antd";

function FormInputItem(props) {
  const { name, label, message, placeholder } = props;
  return (
    <Form.Item
      {...props}
      name={name}
      label={label}
      rules={
        message
          ? [
              {
                required: true,
                message: message,
              },
            ]
          : []
      }
    >
      <Input allowClear showCount={props.maxcount} maxLength={props.maxcount} placeholder={placeholder} />
    </Form.Item>
  );
}

export default FormInputItem;
