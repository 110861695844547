import React from "react";
import { Empty } from "antd";
import { Area } from "@ant-design/plots";
function CardLineArea(props) {
  const { data } = props;
  const config = {
    data,
    xField: "statDate",
    yField: "count",
    xAxis: {
      label: null,
      line: null,
    },
    yAxis: {
      label: null,
      grid: {
        line: null,
      },
    },
  };

  if (!data.length) {
    return (
      <div className="empty-wrap">
        <Empty />
      </div>
    );
  }
  return <Area style={{ height: 80, width: "100%" }} {...config} />;
}

export default CardLineArea;
