import React, { Suspense } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Header from "@/components/Header/Header";
import Home from "@/pages/Home/Home";
import PlanStatPK from "@/pages/Home/PlanStatPK";

import PlanList from "@/pages/Plan/PlanList";
import AddPlan from "@/pages/Plan/AddPlan";
import EditPlan from "@/pages/Plan/EditPlan";
import PlanDetail from "@/pages/Plan/PlanDetail";
import PortalSetting from "@/pages/Setting/Portal";
import DivideSetting from "@/pages/Setting/DivideInto";
import AddPortalSetting from "@/pages/Setting/AddPortalSetting";
import AddDivideSetting from "@/pages/Setting/AddDivideSetting";
import PortalList from "@/pages/Portal/PortalList";
import AddPortal from "@/pages/Portal/AddPortal";
import EditPortal from "@/pages/Portal/EditPortal";
import Weights from "@/pages/Portal/Weights";
import IncomeList from "@/pages/Income/IncomeList";
import Login from "@/pages/Auth/Login";
import Footer from "@/components/Footer/Footer";
import PageLoading from "@/components/PageLoading";
import ErrorPage from "@/pages/ErrorPage/ErrorPage";
import Page403 from "@/pages/ErrorPage/403";
import InjectAxiosInterceptors from "./InjectAxiosInterceptors";

// import CBreadCrumb from "./CBreadCrumb";

const BasicRoute = (props) => (
  <Suspense fallback={<PageLoading />}>
    <Router>
      <InjectAxiosInterceptors />
      <div className="mgy">
        <Header />
        <div className="mgy-wrap">
          {/* <CBreadCrumb /> */}
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/PlanStatPK" component={PlanStatPK} />

            <Route exact path="/Plan" component={PlanList} />
            <Route exact path="/Plan/AddPlan" component={AddPlan} />
            <Route exact path="/Plan/EditPlan" component={EditPlan} />
            <Route exact path="/Plan/PlanDetail" component={PlanDetail} />
            <Route exact path="/Setting/Portal" component={PortalSetting} />
            <Route exact path="/Setting/DivideInto" component={DivideSetting} />
            <Route
              exact
              path="/Setting/AddPortalSetting"
              component={AddPortalSetting}
            />
            <Route
              exact
              path="/Setting/AddDivideSetting"
              component={AddDivideSetting}
            />
            <Route exact path="/Portal/PortalList" component={PortalList} />
            <Route exact path="/Portal/AddPortal" component={AddPortal} />
            <Route exact path="/Portal/EditPortal" component={EditPortal} />
            <Route exact path="/Portal/Weights" component={Weights} />
            <Route exact path="/Income" component={IncomeList} />

            <Route exact path="/Login" component={Login} />
            <Route exact path="/ErrorPage/403" component={Page403} />
            <Route path="*">
              <ErrorPage />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  </Suspense>
);

export default BasicRoute;
