import Http from "../utils/Http";
import { omit } from "lodash";

//portal配置列表
export function getPortalSettingList(data) {
  const result = Http({
    url: "/share-wifi/wifi-config",
    method: "GET",
    data,
  });
  return result;
}

//portal配置列表编辑
export function portalSettingEdit(data) {
  const result = Http({
    url: "/share-wifi/wifi-config" + data._url,
    method: "PUT",
    data: omit(data, "_url"),
  });
  return result;
}

//portal配置列表删除
export function portalSettingDel(data) {
  const result = Http({
    url: "/share-wifi/wifi-config",
    method: "DELETE",
    data,
  });
  return result;
}
//portal配置列表新增
export function portalSettingAdd(data) {
  const result = Http({
    url: "/share-wifi/wifi-config",
    method: "POST",
    data,
  });
  return result;
}
