import React, { useState, useEffect } from "react";
import { Breadcrumb, message, Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CTable from "@/components/CTable";
import { useRequest } from "ahooks";
import {
  getGlobalConfig,
  globalConfigEdit,
  globalConfigDel,
} from "@/api/config";
import EditableCell from "@/components/EditableCell/EditableCell";
import EditableRow from "@/components/EditableRow/EditableRow";

const { confirm } = Modal;

function DivideSetting(props) {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const [columns] = useState([
    {
      title: "国家",
      dataIndex: "country",
      render: (_, record) => record.country.name
    },
    {
      title: "单价配置",
      dataIndex: "thousandPrice",
      render: (_, record) => record.thousandPrice + "%",
      editable: true,
      width: 200,
    },
    {
      title: "操作",
      render: (_, record) => (
        <>
          <Button
            danger
            type="link"
            onClick={() => {
              delConfirm(record);
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ]);

  const delConfirm = (item) => {
    console.log("delete", item);
    confirm({
      title: "确认关闭该配置?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        delData.run(item.id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (row) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    //console.log(newData);
    //console.log(item, row);
    if (item.thousandPrice !== row.thousandPrice) {
      editData.run({
        _url: "/" + row.id,
        thousandPrice: row.thousandPrice,
      });
    }
  };

  const eidtColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        type: 2, // 1：数字输入框  2：文本输入框
      }),
    };
  });

  //列表
  const getData = useRequest(
    () => {
      return getGlobalConfig();
    },
    {
      manual: true,
      onSuccess: (res) => {
        console.log("列表", res);
        const result = res.data.data;
        setData(result.list);
      },
      onError: (error) => {
        console.log("列表 error", error);
      },
    }
  );

  //编辑
  const editData = useRequest(
    (params) => {
      return globalConfigEdit(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("edit onFinish", res);
        message.success("编辑成功");
        const p = {
          ...{ limit: pagination.pageSize, page: pagination.current },
        };
        getData.run(p);
      },
      onError: (error) => {
        console.log("edit error", error);
      },
    }
  );

  //删除
  const delData = useRequest(
    (params) => {
      return globalConfigDel(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("delete onFinish", res);
        const p = {
          ...{ limit: pagination.pageSize, page: pagination.current },
        };
        getData.run(p);
      },
      onError: (error) => {
        console.log("delete error", error);
      },
    }
  );

  const handleTableChange = (newPagination) => {
    console.log(newPagination);
    setData([]);
    setPagination(newPagination);
    const params = {
      ...{ limit: newPagination.pageSize, page: newPagination.current },
    };
    getData.run(params);
  };

  //首次加载
  useEffect(() => {
    const params = {
      ...{ limit: pagination.pageSize, page: pagination.current },
    };
    getData.run(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mgy-setting">
      <Breadcrumb
        items={[
          {
            title: "配置",
          },
          {
            title: "单价配置",
          },
        ]}
      />
      <div className="opt-bar">
        <Button
          className="addBtn"
          type="primary"
          onClick={() => {
            console.log("add");
            props.history.push("/Setting/AddDivideSetting");
          }}
        >
          添加分成配置
        </Button>
      </div>
      <CTable
        loading={getData.loading}
        rowClassName={() => "editable-row"}
        components={components}
        columns={eidtColumns}
        size="middle"
        dataSource={data}
        onChange={handleTableChange}
        pagination={pagination}
        rowKey={(record) => record.id}
      />
    </div>
  );
}

export default DivideSetting;
