import React, { useState, useEffect } from "react";
import { Form, Upload, ColorPicker, message, Button, Modal } from "antd";
import { FileImageOutlined, VideoCameraOutlined } from "@ant-design/icons";
import FormInputItem from "@/components/FormInputItem";
import { useRequest } from "ahooks";
import { uploadVideo, uploadVideoImage } from "@/api/portal";

function VideoForm(props) {
  const { resource, type } = props;
  console.log(props);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [videoList, setvideoList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uri, setUri] = useState("");

  const { loading, run } = useRequest(
    (params) => {
      return uploadVideo(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("video upload onFinish", res);
        const result = res.data.data;
        setVideoSrc(result.uri);
        const source = result.host + result.uri;
        props.onVideoUploaded(source);
        message.success("上传成功");
      },
      onError: (error) => {
        console.log("video upload error", error);
      },
    }
  );

  const videoImageUpload = useRequest(
    (params) => {
      return uploadVideoImage(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("image upload onFinish", res);
        const result = res.data.data;
        setUri(result.uri);
        const source = result.host + result.uri;
        props.onImageUploaded(source);
        message.success("上传成功");
      },
      onError: (error) => {
        console.log("image upload error", error);
      },
    }
  );

  const normFile = (e) => {
    //console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    //console.log("handlePreview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
    setPreviewOpen(true);
  };

  const handleUpload = () => {
    console.log("handleUpload", videoList);
    const formData = new FormData();
    formData.append("file", videoList[0].originFileObj);
    console.log(formData);
    run(formData);
  };

  const handleUploadImage = () => {
    console.log("上传视频封面", fileList);
    const formData = new FormData();
    formData.append("file", fileList[0].originFileObj);
    console.log(formData);
    videoImageUpload.run(formData);
  };

  //视频
  const videoProps = {
    accept: "video/mp4",
    maxCount: 1,
    defaultFileList: resource ? resource.videoList : [],
    showUploadList: resource ? true : videoList.length,
    // onPreview: (file) => {
    //   console.log("onPreview", file);
    //   handlePreview(file);
    // },
    onRemove: (file) => {
      const index = videoList.indexOf(file);
      const newvideoList = videoList.slice();
      newvideoList.splice(index, 1);
      setvideoList(newvideoList);
      setVideoSrc("");
    },
    beforeUpload: (file) => {
      console.log("beforeUpload", file);
      if (file.size / 1024 / 1024 > 100) {
        message.error("文件大小超出100MB，请重新上传!");
        setvideoList([]);
        return;
      }
      setvideoList([...videoList, file]);
      return false;
    },
    onChange: (file) => {
      console.log("onchange", file);
      setvideoList(file.fileList);
      setVideoSrc("");
    },
  };

  //封面
  const posterProps = {
    accept: "image/png, image/jpeg, image/jpg",
    maxCount: 1,
    listType: "picture-card",
    showUploadList: resource ? true : fileList.length,
    onPreview: (file) => {
      console.log("onPreview", file);
      handlePreview(file);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setUri("");
    },
    beforeUpload: (file) => {
      console.log("beforeUpload", file);
      if (file.size / 1024 > 400) {
        message.error("文件大小超出400kb，请重新上传!");
        setFileList([]);
        return;
      }
      setFileList([...fileList, file]);
      return false;
    },
    onChange: (file) => {
      console.log("onchange", file);
      setFileList(file.fileList);
      setUri("");
    },
  };

  useEffect(() => {
    if (resource) {
      console.log(resource);
      setUri(resource.resource.poster);
      setVideoSrc(resource.resource.videoSrc);
      //   setFileList(resource.fileList);
      //   setvideoList(resource.videoList);
    }
  }, [resource]);

  return (
    <>
      {/* 手动上传视频 */}
      {!type || type === 1 ? (
        <>
          <Form.Item label="上传视频">
            <Form.Item
              name="dragger"
              valuePropName="videoList"
              getValueFromEvent={normFile}
              noStyle
              rules={[
                {
                  required: true,
                  message: "请上传视频",
                },
              ]}
            >
              <Upload.Dragger {...videoProps} style={{ marginBottom: 12 }}>
                <p className="ant-upload-drag-icon">
                  <VideoCameraOutlined />
                </p>
                <p className="ant-upload-text">
                  {"（推荐尺寸：720px X 1280px）"}
                </p>
                <p className="ant-upload-hint">{"MP4，<100M，时长>5s"}</p>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item style={{ margin: 0 }}>
              {videoSrc ? (
                <></>
              ) : (
                <Form.Item style={{ marginTop: 20 }}>
                  <Button
                    type="primary"
                    onClick={handleUpload}
                    disabled={videoList.length === 0}
                    loading={loading}
                  >
                    {loading ? "上传中" : "开始上传"}
                  </Button>
                </Form.Item>
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item label="视频封面">
            <Form.Item
              name="poster"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
              rules={[
                {
                  required: true,
                  message: "请上传视频封面",
                },
              ]}
            >
              <Upload.Dragger {...posterProps} style={{ marginBottom: 12 }}>
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined />
                </p>
                <p className="ant-upload-text">
                  {"（推荐尺寸：720px X 1280px）"}
                </p>
                <p className="ant-upload-hint">{"JPG/JPEG/PNG，小于400KB"}</p>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item style={{ margin: 0 }}>
              {uri ? (
                <></>
              ) : (
                <Form.Item style={{ marginTop: 20 }}>
                  <Button
                    type="primary"
                    onClick={handleUploadImage}
                    disabled={fileList.length === 0}
                    loading={videoImageUpload.loading}
                  >
                    {videoImageUpload.loading ? "上传中" : "开始上传"}
                  </Button>
                </Form.Item>
              )}
            </Form.Item>
          </Form.Item>
        </>
      ) : (
        // 导入外部链接
        <>
          <FormInputItem
            name="videoLink"
            label="视频链接"
            message="请输入视频链接"
            placeholder="请输入视频链接"
          />
          <FormInputItem
            name="posterLink"
            label="视频封面"
            message="请输入视频封面链接"
            placeholder="请输入视频封面链接"
          />
        </>
      )}

      <FormInputItem
        name="desc"
        label="文案"
        message="请输入文案"
        placeholder="请输入文案"
        maxcount={30}
      />
      <Form.Item name="btnColor" label="按钮颜色">
        <ColorPicker />
      </Form.Item>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt=""
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}

export default VideoForm;
