import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
let timeout = null;
function TextPortal(props) {
  const { data } = props;
  const host = data.resource.poster.host;
  const [left, setLeft] = useState(data.resource.duration);

  useEffect(()=>{
    window.clearTimeout(timeout);
    timeout = null
    setLeft(data.resource.duration)
  },[data.resource.duration])

  useEffect(() => {
    const hdHt = document
      .querySelector(".header")
      .getBoundingClientRect().height;
    document.querySelector(".content").style.height = 550 - hdHt + "px";
    
    timeout = setTimeout(() => {
      if (left > 0) {
        setLeft((c) => c - 1);
      } else {
        window.clearTimeout(timeout);
        timeout = null
      }
    }, 1000);
  }, [left]);
  return (
    <div
      className="portal-wrap"
      style={{ background: data.resource.backgroundColor }}
    >
      <div className="header">
        <div className="header-con">
          <div>Watch Ads for free and secured Wi-Fi connection</div>
          {left ? <div>{left}s</div> : <></>}
        </div>
      </div>
      <div className="content">
        <div className="text-wrap">
          <h1>{data.resource.title}</h1>
          {data.resource.sourceType === 2 ? (
            <Swiper
              autoplay={{
                delay: 2000,
              }}
              modules={[Autoplay]}
            >
              {data.resource.poster.map((item, index) => {
                return (
                  <SwiperSlide className="bd" key={index}>
                    <a href={item.redirectLink} rel="noreferrer" target="_blank"><img src={item.posterLink} alt="" /></a>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <Swiper
              autoplay={{
                delay: 2000,
              }}
              modules={[Autoplay]}
            >
              {data.resource.poster.uris.map((item, index) => {
                return (
                  <SwiperSlide className="bd" key={index}>
                    <img src={host + item} alt="" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          <div className="desc">{data.resource.desc}</div>
          <a
            rel="noreferrer"
            href={data.target}
            target="_blank"
            className="btn"
            style={{ background: data.resource.btnColor }}
          >
            {data.button}
          </a>
        </div>
      </div>
    </div>
  );
}

export default TextPortal;
