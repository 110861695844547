import React from "react";
import { Button, Form, Breadcrumb, message, Select } from "antd";
import { wifiDuration } from "@/config/AppConfig";

// import FormInputItem from "@/components/FormInputItem";
import { portalSettingAdd, getPortalSettingList } from "@/api/setting";
import { useRequest } from "ahooks";
import store from "@/store";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

function AddPortalSetting(props) {
  const { allProduct, allCountry } = store;

  //列表
  useRequest(
    () => {
      return getPortalSettingList();
    },
    {
      onSuccess: (res) => {
        console.log("列表", res);
      },
      onError: (error) => {
        console.log("列表 error", error);
      },
    }
  );

  const onAdd = useRequest(
    (params) => {
      return portalSettingAdd(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("add onFinish", res);
        message.success("添加成功");
        setTimeout(() => {
          props.history.goBack();
        }, 500);
      },
      onError: (error) => {
        console.log("upload error", error);
      },
    }
  );

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    onAdd.run(values);
  };

  return (
    <div className="add-ope">
      <Breadcrumb
        items={[
          {
            title: "配置",
          },
          {
            title: (
              <span
                className="nav-links"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                portal配置
              </span>
            ),
          },
          {
            title: "添加Portal配置",
          },
        ]}
      />
      <div className="ope-wrap">
        <Form
          name="validate_other"
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={{}}
        >
          {/* <FormInputItem
            name="ssid"
            label="SSID"
            message="请输入SSID"
            placeholder="请输入SSID"
          /> */}
          <Form.Item
            rules={[
              {
                required: true,
                message: "请选择国家",
              },
            ]}
            name="countryCode"
            label="国家"
          >
            <Select placeholder="请选择国家" options={allCountry} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "请选择型号",
              },
            ]}
            name="model"
            label="型号"
          >
            <Select placeholder="请选择型号" options={allProduct} />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "请选择时长",
              },
            ]}
            name="duration"
            label="免费时长"
          >
            <Select placeholder="请选择免费时长" options={wifiDuration} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 12,
              offset: 6,
            }}
          >
            <Button type="primary" htmlType="submit" loading={onAdd.loading}>
              提交
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              onClick={() => {
                props.history.goBack();
              }}
            >
              返回
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AddPortalSetting;
