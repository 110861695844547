import dayjs from "dayjs";

export const renderStatus = (record) => {
  let txt = record.status;
  // const serverSt = dayjs(serverTime).valueOf();
  // const startSt = dayjs(record.startDate).valueOf();
  // const endSt = dayjs(record.endDate).valueOf();
  // if (!record.startDate) {
  //   //长期投放
  //   txt = "<span class='green'>进行中</span>";
  // } else {
  //   if (serverSt >= startSt && serverSt <= endSt) {
  //     txt = "<span class='green'>进行中</span>";
  //   }
  //   if (serverSt < startSt) {
  //     txt = "<span class='blue'>排期中</span>";
  //   }
  //   if (serverSt > endSt) {
  //     txt = "<span class='red'>已结束</span>";
  //   }
  // }
  if (record.status === 1) {
    txt = "<span class='blue'>排期中</span>";
  } else if (record.status === 2) {
    txt = "<span class='green'>投放中</span>";
  } else if (record.status === 3) {
    txt = "<span class='grey'>已完成</span>";
  } else if (record.status === 4) {
    txt = "<span class='red'>已关闭</span>";
  }
  return txt;
};

export const renderModels = (models) => {
  const arr = [];
  models.forEach((item) => {
    arr.push(item.model);
  });
  return arr.toString();
};

export const renderCountryName = (allCountry, record) => {
  if (record.countryCode) {
    //portal配置
    const d = [...allCountry].find((p) => p.countryCode === record.countryCode);
    return d?.name;
  } else {
    //计划列表
    const d = [...allCountry].filter((p) =>
      [...record.countryCodes].some((item) => item === p.value)
    );
    const arr = [];
    d.forEach((item) => {
      arr.push(item.name);
    });
    return arr.toString();
  }
};

export const renderTime = (record) => {
  if (record) {
    let date = "";
    let time = "";

    switch (record.dateType) {
      case 1:
        date = "长期投放";
        break;
      case 2:
        date = record.startDate + " / " + record.endDate;
        break;
      case 3:
        date = "闲时投放";
        break;
      default:
        date = record.dateType;

        break;
    }

    if (record.startTime) {
      //有时间
      time = record.startTime + " 至 " + record.endTime;
    } else {
      //全天投放
      time = "全天";
    }

    return date + "【" + time + "】";
  }
};

/**
 * 获取设备和系统信息
 * @returns environment
 */
export function getMobSysInfo(navigator) {
  return {
    os: "Android",
    model: "HUAWEI",
  };
}

function stampFormat(stamp) {
  if (!stamp) {
    return false;
  }
  return String(stamp).length === 10 ? dayjs.unix(stamp) : dayjs(stamp);
}

export function formatStampToDate(stamp, type) {
  if (!stamp) {
    return "";
  }
  return type === "date"
    ? stampFormat(stamp).format("YYYY-MM-DD")
    : stampFormat(stamp).format("YYYY-MM-DD HH:mm:ss");
}

// toNonExponential(3.3e-7); // "0.00000033"
// toNonExponential(3e-7); // "0.0000003"
// toNonExponential(1.401e10); // "14010000000"
// toNonExponential(0.0004); // "0.0004"
export function toNonExponential(num) {
  var m = (num - 0).toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
}

export function formatSeconds(value) {
  var theTime = parseInt(value); // 需要转换的时间秒
  var theTime1 = 0; // 分
  var theTime2 = 0; // 小时
  var theTime3 = 0; // 天
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
      if (theTime2 > 24) {
        //大于24小时
        theTime3 = parseInt(theTime2 / 24);
        theTime2 = parseInt(theTime2 % 24);
      }
    }
  }
  var result = "";
  if (theTime > 0) {
    result = "" + parseInt(theTime) + "秒";
  }
  if (theTime1 > 0) {
    result = "" + parseInt(theTime1) + "分" + result;
  }
  if (theTime2 > 0) {
    result = "" + parseInt(theTime2) + "小时" + result;
  }
  if (theTime3 > 0) {
    result = "" + parseInt(theTime3) + "天" + result;
  }
  return result;
}

/**
 * 金额格式化 100000000 => 100,000,000
 * @param {Number} money 金额
 */
export function formatMoney(money) {
  //console.log(money);
  return (money - 0).toLocaleString("en-US", { maximumSignificantDigits: 21 });
  //const regForm = /(\d{1,3})(?=(\d{3})+(?:$|\.))/g;
  //return money.toString().replace(regForm, '$1,');
  // return money.toLocaleString('en-US');
}

/**
 * 关键信息隐藏
 * @param str 字符串
 * @param frontLen 字符串前面保留位数
 * @param endLen 字符串后面保留位数
 * @returns {string}
 */
export function hideCode(str, frontLen, endLen) {
  const len = str.length - frontLen - endLen;
  let xing = "";
  for (var i = 0; i < len; i++) {
    xing += "*";
  }
  return (
    str.substring(0, frontLen) +
    xing.substring(0, 4) +
    str.substring(str.length - endLen)
  );
}
