import React, { useState } from "react";
import { Card, Button, Tag, Tooltip } from "antd";
import CTable from "@/components/CTable";
import { useRequest } from "ahooks";
import { indexPlan } from "@/api";
import { useHistory } from "react-router-dom";
import { renderStatus, renderTime } from "@/utils";
import TogglePlan from "@/components/TogglePlan/TogglePlan";
import { InfoCircleOutlined } from "@ant-design/icons";

function Plan(props) {
  const history = useHistory();
  const [limit] = useState(5);
  const [data, setData] = useState([]);
  const [serverTime, setServerTime] = useState(null);
  const columns = [
    {
      title: "计划名称",
      dataIndex: "name",
    },
    {
      title: "国家/型号",
      dataIndex: "models",
      render: (_, record) => {
        if (record.dateType === 3) {
          return record.country.name;
        }
        return record.models.map((item, index) => {
          return (
            <span key={index}>
              {"【" + item.country.name + "】" + item.model} <br />
            </span>
          );
        });
      },
    },
    {
      title: "计划时间",
      dataIndex: "startDate",
      render: (_, record) => renderTime(record),
    },
    {
      title: (
        <div>
          计划量&nbsp;
          <Tooltip placement="top" title="蓝色CPM；绿色CPC">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: "count",
      render: (_, record) =>
        record.autoClose === 1 ? (
          <>
            {record.type === 1 ? (
              <Tag color="green">{record.count}</Tag>
            ) : (
              <Tag color="blue">{record.count}</Tag>
            )}
          </>
        ) : (
          <>--</>
        ),
    },
    {
      title: "点击/曝光量",
      dataIndex: "cpm",
      render: (_, record) =>
        record.dateType === 3 ? "--" : record.cpc + " / " + record.cpm,
    },
    {
      title: "点击率",
      width: "120px",
      dataIndex: "cpc",
      render: (_, record) =>
        record.dateType === 3
          ? "--"
          : (record.cpc === 0
              ? 0
              : (record.cpc / record.cpm || 0) * 100
            ).toFixed(2) + "%",
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (_, record) => (
        <div
          dangerouslySetInnerHTML={{
            __html: renderStatus(record, serverTime),
          }}
        ></div>
      ),
    },
    {
      title: "操作",
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              onEdit(record);
            }}
          >
            编辑
          </Button>
          <TogglePlan record={record} onConfirm={onConfirm} />
        </>
      ),
    },
  ];
  const onEdit = (record) => {
    console.log(record);
    history.push("/Plan/PlanDetail?from=home&planId=" + record.planId);
  };

  const onConfirm = () => {
    getPlan.refresh();
  };

  //table
  const getPlan = useRequest(
    () => {
      return indexPlan(limit);
    },
    {
      onSuccess: (res) => {
        console.log("最近计划", res);
        const result = res.data.data;
        setData(result.list);
        setServerTime(result.serverTime);
        props.loadedList(result);
      },
      onError: (error) => {
        console.log("最近计划", error);
      },
    }
  );
  return (
    <Card
      title="投放计划"
      extra={
        <Button
          type="link"
          onClick={() => {
            console.log("add");
            history.push("/Plan/AddPlan?from=home");
          }}
        >
          添加计划
        </Button>
      }
    >
      <CTable
        loading={getPlan.loading}
        columns={columns}
        size="small"
        dataSource={data}
        hidePagination={true}
        rowKey={(record) => record.planId}
      />
    </Card>
  );
}

export default Plan;
