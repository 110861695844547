import React, { useRef, useState } from "react";
import { Button, Form, Breadcrumb, message, Radio, Select, Modal } from "antd";

import PortalPreview from "@/components/PortalPreview/PortalPreview";

import FormInputItem from "@/components/FormInputItem";
import { useRequest } from "ahooks";
import qs from "qs";
import { portalAdd } from "@/api/portal";
import VideoForm from "@/pages/Portal/Components/VideoForm";
import ImageForm from "@/pages/Portal/Components/ImageForm";
import ImageTextForm from "@/pages/Portal/Components/ImageTextForm";
import LinkForm from "@/pages/Portal/Components/LinkForm";
import { btnTxtList } from "@/config/AppConfig";

const allPortals = [
  {
    value: 1,
    label: "竖版视频 9:16",
  },
  {
    value: 2,
    label: "竖版大图 9:16",
  },
  {
    value: 3,
    label: "图文广告",
  },
  {
    value: 4,
    label: "外链广告",
  },
];

const sourceTypes = [
  {
    value: 2,
    label: "链接地址",
  },
  {
    value: 1,
    label: "手动上传",
  },
];

const formItemLayout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 17,
  },
};

function AddPortal(props) {
  const { search } = props.location;
  const source = qs.parse(search.replace(/^\?/, ""));
  const from = source.from;
  const formRef = useRef();
  const [prevData, setPrevData] = useState({});
  const [pcPrevData, setPcPrevData] = useState({});
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(1);
  const [video, setVideo] = useState(null);
  const [poster, setPoster] = useState(null);
  const [sourceType, setSourceType] = useState(2);

  const onAdd = useRequest(
    (params) => {
      return portalAdd(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("add onFinish", res);
        message.success("添加成功");
        setTimeout(() => {
          props.history.goBack();
        }, 500);
      },
      onError: (error) => {
        console.log("add error", error);
      },
    }
  );

  const onFinish = (values) => {
    console.log(values);
    let params = {
      ...values,
    };
    if (values.type === 1) {
      params.resource = {
        videoSrc: values.sourceType === 2 ? values.videoLink : video,
        poster: values.sourceType === 2 ? values.posterLink : poster,
        desc: values.desc,
        sourceType: values.sourceType,
        btnColor:
          typeof values.btnColor === "string"
            ? values.btnColor
            : values.btnColor.toHexString(),
      };
    } else if (values.type === 2) {
      params.resource = {
        poster: values.sourceType === 2 ? values.sourceLink : poster,
        desc: values.desc,
        duration: values.duration,
        sourceType: values.sourceType,
        btnColor:
          typeof values.btnColor === "string"
            ? values.btnColor
            : values.btnColor.toHexString(),
      };
    } else if (values.type === 3) {
      params.resource = {
        poster: values.sourceType === 2 ? values.sourceLink : poster,
        title: values.title,
        desc: values.desc,
        duration: values.duration,
        sourceType: values.sourceType,
        btnColor:
          typeof values.btnColor === "string"
            ? values.btnColor
            : values.btnColor.toHexString(),
        backgroundColor:
          typeof values.backgroundColor === "string"
            ? values.backgroundColor
            : values.backgroundColor.toHexString(),
      };
    } else if (values.type === 4) {
      console.log("外链广告");
      params.resource = {
        link: values.link,
        desc: values.desc,
        duration: values.duration,
        btnColor:
          typeof values.btnColor === "string"
            ? values.btnColor
            : values.btnColor.toHexString(),
      };
    }

    console.log("表单提交: ", params);
    onAdd.run(params);
  };

  const onPreview = (deviceType) => {
    formRef.current
      .validateFields()
      .then((values) => {
        console.log("onPreview", values);
        if (values.type === 1) {
          values.resource = {
            videoSrc: values.sourceType === 2 ? values.videoLink : video,
            poster: values.sourceType === 2 ? values.posterLink : poster,
            sourceType: values.sourceType,
            desc: values.desc,
            btnColor:
              typeof values.btnColor === "string"
                ? values.btnColor
                : values.btnColor.toHexString(),
          };
          if(values.sourceTyp === 1){
            if (!video) {
              message.error("请上传视频");
              return;
            }
            if (!poster) {
              message.error("请上传视频封面");
              return;
            }
          }

        } else if (values.type === 2) {
          values.resource = {
            poster: values.sourceType === 2 ? values.sourceLink : poster,
            desc: values.desc,
            duration: values.duration,
            sourceType: values.sourceType,
            btnColor:
              typeof values.btnColor === "string"
                ? values.btnColor
                : values.btnColor.toHexString(),
          };
          if(values.sourceTyp === 1){
            if (!poster) {
              message.error("请上传广告图片");
              return;
            }
          }

        } else if (values.type === 3) {
          values.resource = {
            poster: values.sourceType === 2 ? values.sourceLink : poster,
            title: values.title,
            desc: values.desc,
            duration: values.duration,
            sourceType: values.sourceType,
            btnColor:
              typeof values.btnColor === "string"
                ? values.btnColor
                : values.btnColor.toHexString(),
            backgroundColor:
              typeof values.backgroundColor === "string"
                ? values.backgroundColor
                : values.backgroundColor.toHexString(),
          };
          if(values.sourceTyp === 1){
            if (!poster) {
              message.error("请上传广告图片");
              return;
            }
          }

        } else if (values.type === 4) {
          values.resource = {
            link: values.link,
            desc: values.desc,
            duration: values.duration,
            btnColor:
              typeof values.btnColor === "string"
                ? values.btnColor
                : values.btnColor.toHexString(),
          };
          console.log("外链广告", values);
        }
        if (deviceType) {
          setPcPrevData(values);
          setVisible(true);
        } else {
          setPrevData(values);
        }
      })
      .catch((errorInfo) => {
        console.log("error info", errorInfo);
      });
  };

  const onVideoUploaded = (source) => {
    setVideo(source);
  };

  const onImageUploaded = (source) => {
    setPoster(source);
  };

  const onTypeChange = (e) => {
    setType(e.target.value);
    //console.log(formRef.current.getFieldsValue())
    formRef.current.setFieldValue("pics", []);
    formRef.current.setFieldValue("sourceLink", [{}]);
    setPoster(null);
    setPrevData({});
  };

  const onSourceTypeChange = (e) => {
    setSourceType(e.target.value);
  };

  return (
    <div className="add-ope">
      <Breadcrumb
        items={[
          {
            title: (
              <span
                className="nav-links"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                {from ? "添加计划" : "Portal列表"}
              </span>
            ),
          },
          {
            title: "添加Portal",
          },
        ]}
      />
      <div className="ope-wrap">
        <Form
          ref={formRef}
          scrollToFirstError={true}
          name="validate_other"
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={{
            type: 1,
            sourceType: sourceType,
            btnColor: "#1677FF",
            backgroundColor: "#F3D28D",
            duration: 10,
            sourceLink: [{}]
          }}
        >
          <FormInputItem
            name="name"
            label="Portal名称"
            message="请输入Portal名称"
            placeholder="请输入Portal名称"
          />

          <Form.Item
            rules={[
              {
                required: true,
                message: "请选择Portal模板",
              },
            ]}
            name="type"
            label="Portal模板"
          >
            <Radio.Group buttonStyle="solid" onChange={onTypeChange}>
              {allPortals.map((item, index) => {
                return (
                  <Radio.Button key={index} value={item.value}>
                    {item.label}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
          {type !== 4 && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "请选择素材来源",
                },
              ]}
              name="sourceType"
              label="素材来源"
            >
              <Radio.Group buttonStyle="solid" onChange={onSourceTypeChange}>
                {sourceTypes.map((item, index) => {
                  return (
                    <Radio.Button key={index} value={item.value}>
                      {item.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          )}

          {type === 1 && (
            <VideoForm
              onVideoUploaded={onVideoUploaded}
              onImageUploaded={onImageUploaded}
              type={sourceType}
            />
          )}
          {type === 2 && <ImageForm onImageUploaded={onImageUploaded} type={sourceType} />}
          {type === 3 && <ImageTextForm onImageUploaded={onImageUploaded} type={sourceType} />}
          {type === 4 && <LinkForm />}

          <Form.Item
            name="button"
            label="按钮文案"
            rules={[
              {
                required: true,
                message: "请选择按钮文案",
              },
            ]}
          >
            <Select placeholder="请选择按钮文案" options={btnTxtList} />
          </Form.Item>

          <FormInputItem
            name="target"
            label="跳转链接"
            message="请输入跳转链接"
            placeholder="请输入跳转链接"
          />

          <Form.Item
            wrapperCol={{
              span: 12,
              offset: 3,
            }}
          >
            <Button type="primary" htmlType="submit" loading={onAdd.loading}>
              提交
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              onClick={() => {
                props.history.goBack();
              }}
            >
              返回
            </Button>
            <Button
              danger
              style={{ marginLeft: 20 }}
              onClick={() => {
                onPreview();
              }}
            >
              预览
            </Button>
            <Button
              danger
              style={{ marginLeft: 20 }}
              onClick={() => {
                onPreview(2);
              }}
            >
              PC预览
            </Button>
          </Form.Item>
        </Form>
        <PortalPreview portals={prevData} />
      </div>
      <Modal
        className="pc-box"
        width={1000}
        open={visible}
        footer={[]}
        centered
        onCancel={() => {
          setVisible(false);
        }}
      >
        <PortalPreview type={2} portals={pcPrevData} />
      </Modal>
    </div>
  );
}

export default AddPortal;
