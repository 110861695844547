import React, { useState } from "react";
import { Statistic, Card } from "antd";
import { useRequest } from "ahooks";
import { indexPortal } from "@/api";

function Overview(props) {
  const [data, setData] = useState({});
  const { loading } = useRequest(
    () => {
      return indexPortal();
    },
    {
      onSuccess: (res) => {
        console.log("汇总", res);
        const result = res.data.data;
        setData(result);
      },
      onError: (error) => {
        console.log("汇总", error);
      },
    }
  );
  return (
    <Card loading={loading}>
      <div className="overview">
        <Statistic title="总计划" value={data.portal_plan_total} />
        <Statistic title="投放中" value={data.portal_active} />
        <Statistic title="已完成" value={data.portal_finished} />
        <Statistic title="投放数量" value={data.devices} />
        <Statistic title="曝光量" value={data.total_cpm} />
      </div>
    </Card>
  );
}

export default Overview;
