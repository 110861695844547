import Http from "../utils/Http";

export function userLogin(data) {
  const result = Http({
    url: "/auth/login",
    method: "POST",
    data,
  });
  return result;
}

export function logout() {
  const result = Http({
    url: "/auth/logout",
    method: "POST",
  });
  return result;
}

export function verifyImage() {
  const result = Http({
    url: "/auth/captcha",
    method: "POST",
  });
  return result;
}

export function profile() {
  const result = Http({
    url: "/profile",
    method: "POST",
  });
  return result;
}

export function profileEdit(data) {
  const result = Http({
    url: "/profile/edit",
    method: "POST",
    data,
  });
  return result;
}
