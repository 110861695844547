import React, { useState, useEffect } from "react";
import FilterBar from "./Components/FilterBar";
import { pickBy } from "lodash";
import { useRequest } from "ahooks";
import CTable from "@/components/CTable";
import { getPortalList } from "@/api/portal";

function IncomeList(props) {
  const [data, setData] = useState([]);
  const [filterForm, setFilterForm] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const { loading, run } = useRequest(
    (params) => {
      return getPortalList(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("list onFinish", res);
        const result = res.data.data;
        setPagination({
          current: result.mate.page,
          total: result.mate.total,
          pageSize: result.mate.pageSize,
        });
        setData(result.list);
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  const [columns] = useState([
    {
      title: "收入来源",
      dataIndex: "name",
    },
    {
      title: "收入分类",
      dataIndex: "createTime",
    },
    {
      title: "时间",
      dataIndex: "createTime",
    },
    {
      title: "展示量",
      dataIndex: "type",
    },
    {
      title: "点击量",
      dataIndex: "type",
    },
    {
      title: "点击率",
      dataIndex: "type",
    },
  ]);

  const onFinish = (values) => {
    const filterValues = pickBy({ ...values });
    console.log("pickBy", filterValues);
    if (filterValues.times && filterValues.times.length) {
      filterValues.startDate = filterValues.times[0].format("YYYY-MM-DD");
      filterValues.endDate = filterValues.times[1].format("YYYY-MM-DD");
    }
    setFilterForm(filterValues);
    const params = {
      ...filterValues,
      ...{ limit: pagination.pageSize, page: 1 },
    };
    run(params);
  };

  const handleTableChange = (newPagination) => {
    console.log(newPagination);
    setData([]);
    setPagination(newPagination);
    const params = {
      ...filterForm,
      ...{ limit: newPagination.pageSize, page: newPagination.current },
    };
    run(params);
  };

  //首次加载
  useEffect(() => {
    const params = {
      ...{ limit: pagination.pageSize, page: pagination.current },
    };
    run(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FilterBar onFinish={onFinish} {...props} />
      <CTable
        columns={columns}
        dataSource={data}
        loading={loading}
        size="middle"
        sticky={true}
        onChange={handleTableChange}
        pagination={pagination}
        rowKey={(record) => record.portalId}
      />
    </>
  );
}

export default IncomeList;
