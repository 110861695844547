import React, { useState } from "react";
import logo from "@/assets/logo.png";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Col } from "antd";
import { userLogin, verifyImage } from "@/api/login";
import StorageUtil from "@/utils/StorageUtil";
import { useRequest } from "ahooks";
import Store from "@/store/index";
import { useConfig } from "@/config/SystemConfig";
import "./login.scss";

function Login(props) {
  const { setAccessToken } = Store;
  const [init] = useConfig();

  const [captcha, setCaptcha] = useState(null);

  const { loading, run } = useRequest(
    (v) => {
      return userLogin(v);
    },
    {
      manual: true,
      onSuccess: (result, params) => {
        console.log("onFinish", result);
        //StorageUtil.set("mgy_portal_member_info", result.data.data.access_token);
        StorageUtil.set("mgy_portal_accessToken", result.data.data.access_token);
        setAccessToken(result.data.data.access_token);
        init();
        // if (result.data?.data.userinfo.parentId === 0) {
        //   //主账号默认所有路由
        //   console.log(allRouters);
        //   setRouters(allRouters);
        //   Store.arrowHandleWithdraw = true;
        // } else {
        //   const routers = [
        //     ...baseRouters,
        //     ...result.data?.data.userinfo.permissions,
        //   ];
        //   const currentRouters = initRouter(routers);
        //   console.log(currentRouters);
        //   setRouters(currentRouters);
        //   if (
        //     result.data?.data.userinfo.permissions.includes(
        //       "memberWithdrawAllow"
        //     )
        //   ) {
        //     Store.arrowHandleWithdraw = true;
        //   }
        // }
      },
      onError: (error) => {
        console.log("login runAsync error", error);
        getVerifyImage.refresh();
      },
    }
  );

  const getVerifyImage = useRequest(
    () => {
      return verifyImage();
    },
    {
      onSuccess: (result, params) => {
        console.log("图片验证码 onFinish", result);
        setCaptcha(result.data.data.captcha);
      },
      onError: (error) => {
        console.log("runAsync error", error);
      },
    }
  );

  const onFinish = async (values) => {
    const params = {
      key: captcha?.key,
      ...values,
    };
    console.log(params);
    run(params);
  };

  return (
    <div className="mgy-login">
      <div className="login-form">
        <img src={logo} alt="logo" className="logo" />
        <Form
          name="normal_login"
          className="forms"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "请输入账号!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="请输入账号"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "请输入密码!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="请输入密码"
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="captcha"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: "请输入验证码",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                {captcha ? (
                  <img
                    className="code-image"
                    onClick={() => {
                      getVerifyImage.refresh();
                    }}
                    src={captcha.img}
                    alt=""
                  />
                ) : (
                  <Button
                    onClick={() => {
                      getVerifyImage.refresh();
                    }}
                  >
                    获取验证码
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              disabled={loading}
              block
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;
