import React from "react";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Space,
  Table,
  Tag,
  DatePicker,
  Select,
  Row,
  Col,
  Form,
} from "antd";
import PlanStatPKCharts from "@/components/Charts/PlanStatPKCharts";
import "./home.scss";
import { useState } from "react";
import Card from "antd/es/card/Card";
import { getPlans } from "@/api/plan";
import { useRequest } from "ahooks";
import { renderTime, renderStatus } from "@/utils";
import store from "@/store";
import dayjs from "dayjs";
import { pickBy } from "lodash";
const { RangePicker } = DatePicker;

function PlanStatPK(props) {
  const { allProduct, allCountry } = store;
  const [planList, setPlanList] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [selectedList_2, setSelectedList_2] = useState([]);
  const [selectedList_3, setSelectedList_3] = useState([]);
  const [selectedList_4, setSelectedList_4] = useState([]);
  const [form] = Form.useForm();

  const { loading } = useRequest(
    (params) => {
      return getPlans(params);
    },
    {
      onSuccess: (res) => {
        console.log("plans onFinish", res);
        let planListFormat = [];
        res.data.data.forEach((item) => {
          item.value = item.planId;
          item.label = item.name;
          planListFormat[item.status] = planListFormat[item.status] || [];
          planListFormat[item.status].push(item);
        });
        console.log("planListFormat", planListFormat);
        setPlanList(planListFormat);
      },
      onError: (error) => {
        console.log("plans error", error);
      },
    }
  );

  function onChange(selected, list, setSelectedList) {
    const _selectedList = list.filter((p) =>
      selected.some((item) => item === p.planId)
    );
    setSelectedList(_selectedList);
  }

  function generateChart(values) {
    const _filterValues = pickBy({ ...values });
    console.log("pickBy", _filterValues);
    if (_filterValues.times && _filterValues.times.length) {
      _filterValues.startDate = _filterValues.times[0].format("YYYY-MM-DD");
      _filterValues.endDate = _filterValues.times[1].format("YYYY-MM-DD");
    }
    delete _filterValues.times;
    _filterValues.planList = [
      ...selectedList_2,
      ...selectedList_3,
      ...selectedList_4,
    ];
    setFilterValues(_filterValues);
  }

  const columns = [
    {
      title: "计划名称",
      dataIndex: "name",
    },
    {
      title: "计划时间",
      dataIndex: "startDate",
      render: (_, record) => renderTime(record),
    },
    {
      title: "计划量",
      dataIndex: "count",
      render: (_, record) =>
        record.autoClose === 1 ? (
          <>
            {record.type === 1 ? (
              <Tag color="green">{record.count}</Tag>
            ) : (
              <Tag color="blue">{record.count}</Tag>
            )}
          </>
        ) : (
          <>--</>
        ),
    },
    {
      title: "点击量",
      dataIndex: "cpc",
    },
    {
      title: "曝光量",
      dataIndex: "cpm",
    },
    {
      title: "点击率",
      dataIndex: "cpc",
      render: (_, record) =>
        record.dateType === 3
          ? "--"
          : (record.cpc === 0
              ? 0
              : (record.cpc / record.cpm || 0) * 100
            ).toFixed(2) + "%",
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (_, record) => (
        <div
          dangerouslySetInnerHTML={{
            __html: renderStatus(record),
          }}
        ></div>
      ),
    },
  ];

  return (
    <div className="mgy-home">
      <Breadcrumb
        items={[
          {
            title: (
              <span
                className="nav-links"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                首页
              </span>
            ),
          },
          {
            title: "计划数据PK",
          },
        ]}
      />
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: "flex",
        }}
      >
        <Card loading={loading} title="投放中">
          <Checkbox.Group
            options={planList[2] || []}
            onChange={(value) => {
              onChange(value, planList[2], setSelectedList_2);
            }}
          />
        </Card>
        <Card loading={loading} title="已完成">
          <Checkbox.Group
            options={planList[3] || []}
            onChange={(value) => {
              onChange(value, planList[3], setSelectedList_3);
            }}
          />
        </Card>
        <Card loading={loading} title="已关闭">
          <Checkbox.Group
            options={planList[4] || []}
            onChange={(value) => {
              onChange(value, planList[4], setSelectedList_4);
            }}
          />
        </Card>
        <Table
          loading={loading}
          columns={columns}
          dataSource={[...selectedList_2, ...selectedList_3, ...selectedList_4]}
          pagination={false}
          rowKey={(record) => record.planId}
        />

        <Card title="数据对比">
          <div className="filterBar">
            <Card bordered={false} bodyStyle={{ padding: 0 }}>
              <Row>
                <Col>
                  <div className="box">
                    <Form
                      layout={"inline"}
                      form={form}
                      onFinish={generateChart}
                      initialValues={{
                        times: [dayjs().add(-30, "d"), dayjs()],
                      }}
                    >
                      <Row className="rows">
                        <Form.Item
                          name="countryCode"
                          label="国家"
                          style={{ width: 500 }}
                        >
                          <Select
                            maxTagCount="responsive"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="请选择国家"
                            options={allCountry}
                          />
                        </Form.Item>
                      </Row>
                      <Row className="rows">
                        <Form.Item
                          name="model"
                          label="产品型号"
                          style={{ width: 500 }}
                        >
                          <Select
                            maxTagCount="responsive"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="请选择产品型号"
                            options={allProduct}
                          />
                        </Form.Item>
                      </Row>
                      <Row className="rows">
                        <Form.Item name="times" label="时间">
                          <RangePicker
                            presets={[
                              {
                                label: "昨天",
                                value: [
                                  dayjs().add(-1, "d"),
                                  dayjs().add(-1, "d"),
                                ],
                              },
                              {
                                label: "今天",
                                value: [dayjs(), dayjs()],
                              },
                              {
                                label: "近一周",
                                value: [dayjs().add(-7, "d"), dayjs()],
                              },
                              {
                                label: "近一月",
                                value: [dayjs().add(-30, "d"), dayjs()],
                              },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item>
                          <Button htmlType="submit" type="primary">
                            生成数据
                          </Button>
                        </Form.Item>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
          <PlanStatPKCharts filterValues={filterValues} />
        </Card>
      </Space>
    </div>
  );
}

export default PlanStatPK;
