import React from "react";
import { Card, Space } from "antd";
import Overview from "@/pages/Home/Components/Overview";
import DataStatis from "@/pages/Home/Components/DataStatis";
// import LineArea from "@/pages/Home/Components/LineArea";
import Plan from "@/pages/Home/Components/Plan";
import PlanStatPKCharts from "@/components/Charts/PlanStatPKCharts";
import "./home.scss";
import { useState } from "react";
function Home(props) {
  const [planList, setPlanList] = useState([]);
  const loadedList = (data) => {
    console.log("loadedList", data);
    setPlanList(data.list);
  };
  return (
    <div className="mgy-home">
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: "flex",
        }}
      >
        <Overview />
        <DataStatis />
        <Plan loadedList={loadedList} />
        {/* <LineArea /> */}
        <Card
          title="计划数据PK"
          extra={
            <a
              onClick={() => {
                props.history.push("/PlanStatPK");
              }}
            >
              查看更多
            </a>
          }
        >
          <PlanStatPKCharts filterValues={{ planList: planList }} />
        </Card>
      </Space>
    </div>
  );
}

export default Home;
