import React, { useEffect, useRef } from "react";
import { Card, Row, Col, Form, Button, Select } from "antd";

function WeightFilterBar(props) {
  console.log(props);
  const formRef = useRef();
  const [form] = Form.useForm();
  useEffect(() => {
    formRef.current.setFieldValue("model", props.model);
  }, [props.model]);
  return (
    <div className="filterBar">
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Row>
          <Col>
            <div className="box">
              <Form
                layout={"inline"}
                ref={formRef}
                form={form}
                onFinish={props.onFinish}
                // initialValues={{
                //   model: props.model,
                // }}
              >
                <Row className="rows">
                  <Form.Item name="model" label="路由型号">
                    <Select options={props.allProduct} />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      查询
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default WeightFilterBar;
