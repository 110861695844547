import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import { useRequest } from "ahooks";
import { planStatPK } from "@/api/plan";

const PlanStatPKCharts = (props) => {
  const { filterValues } = props;
  console.log("PlanStatPK planList=", props);
  const [data, setData] = useState([]);
  const { run } = useRequest(
    (params) => {
      return planStatPK(params);
    },
    {
      manual: true,
      onSuccess: (res) => {
        console.log("计划数据PK", res);
        const result = [];
        res.data.data.forEach((item) => {
          result.push(
            {
              name: item.plan.name + "- 点击量",
              value: Number(item.cpc),
              statDate: item.statDate,
            },
            {
              name: item.plan.name + "- 曝光量",
              value: Number(item.cpm),
              statDate: item.statDate,
            }
          );
        });
        setData(result);
      },
      onError: (error) => {
        console.log("计划数据PK", error);
      },
    }
  );

  const config = {
    data,
    xField: "statDate",
    yField: "value",
    seriesField: "name",
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    slider: {
      start: 0.7,
      end: 1,
    },
  };

  useEffect(() => {
    let { planList, ...params } = filterValues;
    if (planList?.length) {
      params.planId = [];
      planList.forEach((item) => {
        params.planId.push(item.planId);
      });
      run(params);
    }
  }, [filterValues]);

  return <Column {...config} />;
};

export default PlanStatPKCharts;
