import React from "react";
import { Button, Form, Breadcrumb, message, Select, InputNumber } from "antd";

import { globalConfigAdd } from "@/api/config";
import { useRequest } from "ahooks";
import store from "@/store";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

function AddGlobalConfig(props) {
  const { allCountry } = store;

  const onAdd = useRequest(
    (params) => {
      return globalConfigAdd(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("add onFinish", res);
        message.success("添加成功");
        setTimeout(() => {
          props.history.goBack();
        }, 500);
      },
      onError: (error) => {
        console.log("upload error", error);
      },
    }
  );

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    onAdd.run(values);
  };

  return (
    <div className="add-ope">
      <Breadcrumb
        items={[
          {
            title: "配置",
          },
          {
            title: (
              <span
                className="nav-links"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                单价配置
              </span>
            ),
          },
          {
            title: "添加单价配置",
          },
        ]}
      />
      <div className="ope-wrap">
        <Form
          name="validate_other"
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={{}}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "请选择国家",
              },
            ]}
            name="countryCode"
            label="国家"
          >
            <Select placeholder="请选择国家" options={allCountry} />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "请选择分成比例",
              },
            ]}
            name="thousandPrice"
            label="单价配置"
          >
            <InputNumber addonAfter="%" min={0} placeholder="请输入分成比例" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 12,
              offset: 6,
            }}
          >
            <Button type="primary" htmlType="submit" loading={onAdd.loading}>
              提交
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              htmlType="submit"
              onClick={() => {
                props.history.goBack();
              }}
            >
              返回
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AddGlobalConfig;
