import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Breadcrumb,
  message,
  Radio,
  Select,
  Spin,
  Modal,
} from "antd";

import PortalPreview from "@/components/PortalPreview/PortalPreview";

import FormInputItem from "@/components/FormInputItem";
import { useRequest } from "ahooks";
import qs from "qs";
import { portalEdit, portalDetail } from "@/api/portal";
import VideoForm from "@/pages/Portal/Components/VideoForm";
import ImageForm from "@/pages/Portal/Components/ImageForm";
import ImageTextForm from "@/pages/Portal/Components/ImageTextForm";
import LinkForm from "@/pages/Portal/Components/LinkForm";
import { omit } from "lodash";
import { btnTxtList } from "@/config/AppConfig";

const allPortals = [
  {
    value: 1,
    label: "竖版视频 9:16",
  },
  {
    value: 2,
    label: "竖版大图 9:16",
  },
  {
    value: 3,
    label: "图文广告",
  },
  {
    value: 4,
    label: "外链广告",
  },
];

const sourceTypes = [
  {
    value: 2,
    label: "链接地址",
  },
  {
    value: 1,
    label: "手动上传",
  },
];

const formItemLayout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 17,
  },
};

function EditPortal(props) {
  const { search } = props.location;
  const source = qs.parse(search.replace(/^\?/, ""));
  const portalId = source.portalId;
  const formRef = useRef();
  const [prevData, setPrevData] = useState({});
  const [pcPrevData, setPcPrevData] = useState({});
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(1);
  const [video, setVideo] = useState(null);
  const [poster, setPoster] = useState(null);
  const [detail, setDetail] = useState(null);

  const { loading } = useRequest(
    () => {
      return portalDetail({ _url: "/" + portalId });
    },
    {
      onSuccess: (res, params) => {
        console.log("detail onFinish", res);
        const result = res.data.data;
        if (result.type === 1) {
          //竖版视频
          const videoSrc = result.resource.videoSrc;
          const poster = result.resource.poster;
          const vLen = videoSrc.split("/").length;
          const len = poster.split("/").length;
          result.videoList = [
            {
              uid: "-1",
              name: videoSrc.split("/")[vLen - 1],
              source: videoSrc,
              url: videoSrc,
            },
          ];
          result.fileList = [
            {
              uid: "-2",
              name: poster.split("/")[len - 1],
              source: poster,
              url: poster,
            },
          ];
          setVideo(videoSrc);
          setPoster(poster);
        } else if (result.type === 2 || result.type === 3) {
          const poster = result.resource.poster;
          //图文 && 竖版大图

          if (result.resource.sourceType === 2) {
            //外链
            setPoster(poster);
          } else {
            //手动上传
            const list = [];
            result.resource.poster.uris.forEach((item, index) => {
              const len = item.split("/").length;
              list.push({
                uid: index + "",
                name: item.split("/")[len - 1],
                source: item,
                url: result.resource.poster.host + item,
              });
            });
            result.fileList = list;
            setPoster(poster);
          }
        }

        console.log(result);
        setDetail(result);
        setPrevData(result);
        setType(result.type);
      },
      onError: (error) => {
        console.log("detail error", error);
      },
    }
  );

  const onEdit = useRequest(
    (params) => {
      return portalEdit(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("edit onFinish", res);
        message.success("编辑成功");
        setTimeout(() => {
          //props.history.goBack();
          props.history.replace("/Portal/PortalList");
        }, 500);
      },
      onError: (error) => {
        console.log("edit error", error);
      },
    }
  );

  const onFinish = (values) => {
    console.log(values);
    let params = {
      _url: "/" + portalId,
      ...values,
    };
    if (values.type === 1) {
      if (!video) {
        message.error("请上传视频");
        return;
      }
      if (!poster) {
        message.error("请上传视频封面");
        return;
      }
      params.resource = {
        videoSrc: values.sourceType === 2 ? values.videoLink : video,
        poster: values.sourceType === 2 ? values.posterLink : poster,
        sourceType: values.sourceType,
        desc: values.desc,
        btnColor:
          typeof values.btnColor === "string"
            ? values.btnColor
            : values.btnColor.toHexString(),
      };
      params = omit(params, "dragger");
      params = omit(params, "poster");
    } else if (values.type === 2) {
      params.resource = {
        poster: values.sourceType === 2 ? values.sourceLink : poster,
        duration: values.duration,
        sourceType: values.sourceType,
        desc: values.desc,
        btnColor:
          typeof values.btnColor === "string"
            ? values.btnColor
            : values.btnColor.toHexString(),
      };
      params = omit(params, "pics");
    } else if (values.type === 3) {
      const uris = poster?.uris || detail.resource.poster.uris;
      const editPoster = {
        host: detail.resource.poster.host,
        uris: uris,
      };
      params.resource = {
        poster: values.sourceType === 2 ? values.sourceLink : editPoster,
        duration: values.duration,
        sourceType: values.sourceType,
        title: values.title,
        desc: values.desc,
        btnColor:
          typeof values.btnColor === "string"
            ? values.btnColor
            : values.btnColor.toHexString(),
        backgroundColor:
          typeof values.backgroundColor === "string"
            ? values.backgroundColor
            : values.backgroundColor.toHexString(),
      };
      params = omit(params, "pics");
    } else if (values.type === 4) {
      console.log("外链广告");
      params.resource = {
        link: values.link,
        desc: values.desc,
        duration: values.duration,
        btnColor:
          typeof values.btnColor === "string"
            ? values.btnColor
            : values.btnColor.toHexString(),
      };
      params = omit(params, "pics");
    }

    console.log("表单提交: ", params);
    onEdit.run(params);
  };

  const onPreview = (type) => {
    formRef.current
      .validateFields()
      .then((values) => {
        console.log(values);
        if (values.type === 1) {
          values.resource = {
            videoSrc: values.sourceType === 2 ? values.videoLink : video,
            poster: values.sourceType === 2 ? values.posterLink : poster,
            sourceType: values.sourceType,
            desc: values.desc,
            btnColor:
              typeof values.btnColor === "string"
                ? values.btnColor
                : values.btnColor.toHexString(),
          };
          if(values.sourceTyp === 1){
            if (!video) {
              message.error("请上传视频");
              return;
            }
            if (!poster) {
              message.error("请上传视频封面");
              return;
            }
          }

        } else if (values.type === 2) {
          values.resource = {
            poster: values.sourceType === 2 ? values.sourceLink : poster,
            desc: values.desc,
            duration: values.duration,
            sourceType: values.sourceType,
            btnColor:
              typeof values.btnColor === "string"
                ? values.btnColor
                : values.btnColor.toHexString(),
          };
          if(values.sourceTyp === 1){
            if (!poster) {
              message.error("请上传广告图片");
              return;
            }
          }

        } else if (values.type === 3) {
          values.resource = {
            poster: values.sourceType === 2 ? values.sourceLink : poster,
            title: values.title,
            desc: values.desc,
            duration: values.duration,
            sourceType: values.sourceType,
            btnColor:
              typeof values.btnColor === "string"
                ? values.btnColor
                : values.btnColor.toHexString(),
            backgroundColor:
              typeof values.backgroundColor === "string"
                ? values.backgroundColor
                : values.backgroundColor.toHexString(),
          };
          if(values.sourceTyp === 1){
            if (!poster) {
              message.error("请上传广告图片");
              return;
            }
          }

        } else if (values.type === 4) {
          values.resource = {
            link: values.link,
            desc: values.desc,
            duration: values.duration,
            btnColor:
              typeof values.btnColor === "string"
                ? values.btnColor
                : values.btnColor.toHexString(),
          };
          console.log("外链广告", values);
        }
        console.log(values);
        if (type) {
          setPcPrevData(values);
          setVisible(true);
        } else {
          setPrevData(values);
        }
      })
      .catch((errorInfo) => {
        console.log("error info", errorInfo);
      });
  };

  const onVideoUploaded = (source) => {
    console.log("onVideoUploaded", source);
    setVideo(source);
  };

  const onImageUploaded = (source) => {
    console.log("onImageUploaded", source);
    setPoster(source);
  };

  const onTypeChange = (e) => {
    setType(e.target.value);
  };

  useEffect(() => {
    console.log("detail", detail);
  }, [detail]);

  return (
    <div className="add-ope">
      <Breadcrumb
        items={[
          {
            title: (
              <span
                className="nav-links"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                Portal列表
              </span>
            ),
          },
          {
            title: "编辑Portal",
          },
        ]}
      />
      <Spin spinning={loading} size="large" tip="Loading...">
        <div className="ope-wrap">
          {detail && (
            <>
              <Form
                ref={formRef}
                scrollToFirstError={true}
                name="validate_other"
                {...formItemLayout}
                onFinish={onFinish}
                initialValues={{
                  ...detail,
                  btnColor: detail.resource.btnColor,
                  title: detail.resource.title,
                  backgroundColor: detail.resource.backgroundColor,
                  desc: detail.resource.desc,
                  pics: detail.fileList,
                  poster: detail.fileList,
                  dragger: detail.videoList,
                  duration: detail.resource.duration,
                  link: detail.resource.link,
                  sourceType: detail.resource.sourceType || 2,
                  sourceLink: detail.resource.poster,
                  videoLink: detail.resource.videoSrc,
                  posterLink: detail.resource.poster
                }}
              >
                <FormInputItem
                  name="name"
                  label="Portal名称"
                  message="请输入Portal名称"
                  placeholder="请输入Portal名称"
                />

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "请选择Portal模板",
                    },
                  ]}
                  name="type"
                  label="Portal模板"
                >
                  <Radio.Group
                    disabled
                    buttonStyle="solid"
                    onChange={onTypeChange}
                  >
                    {allPortals.map((item, index) => {
                      return (
                        <Radio.Button key={index} value={item.value}>
                          {item.label}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>

                {type !== 4 && (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "请选择素材来源",
                      },
                    ]}
                    name="sourceType"
                    label="素材来源"
                  >
                    <Radio.Group
                      disabled
                      buttonStyle="solid"
                    >
                      {sourceTypes.map((item, index) => {
                        return (
                          <Radio.Button key={index} value={item.value}>
                            {item.label}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </Form.Item>
                )}

                {type === 1 && (
                  <VideoForm
                    resource={detail}
                    onVideoUploaded={onVideoUploaded}
                    onImageUploaded={onImageUploaded}
                    type={detail.resource.sourceType}
                  />
                )}
                {type === 2 && (
                  <ImageForm
                    resource={detail}
                    onImageUploaded={onImageUploaded}
                    type={detail.resource.sourceType}
                  />
                )}
                {type === 3 && (
                  <ImageTextForm
                    resource={detail}
                    onImageUploaded={onImageUploaded}
                    type={detail.resource.sourceType}
                  />
                )}

                {type === 4 && <LinkForm />}

                <Form.Item
                  name="button"
                  label="按钮文案"
                  rules={[
                    {
                      required: true,
                      message: "请选择按钮文案",
                    },
                  ]}
                >
                  <Select placeholder="请选择按钮文案" options={btnTxtList} />
                </Form.Item>

                <FormInputItem
                  name="target"
                  label="跳转链接"
                  message="请输入跳转链接"
                  placeholder="请输入跳转链接"
                />

                <Form.Item
                  wrapperCol={{
                    span: 12,
                    offset: 3,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={onEdit.loading}
                  >
                    提交
                  </Button>
                  <Button
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                      props.history.goBack();
                    }}
                  >
                    返回
                  </Button>
                  <Button
                    danger
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                      onPreview();
                    }}
                  >
                    预览
                  </Button>
                  <Button
                    danger
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                      onPreview(2);
                    }}
                  >
                    PC预览
                  </Button>
                </Form.Item>
              </Form>
              <PortalPreview portals={prevData} />
            </>
          )}
        </div>
      </Spin>
      <Modal
        width={1000}
        open={visible}
        footer={[]}
        centered
        className="pc-box"
        onCancel={() => {
          setVisible(false);
        }}
      >
        <PortalPreview type={2} portals={pcPrevData} />
      </Modal>
    </div>
  );
}

export default EditPortal;
