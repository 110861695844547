/*
 * APP一些全局配置
 */
import {
  HomeOutlined,
  FilePptOutlined,
  SettingOutlined,
  // MoneyCollectOutlined,
  // SolutionOutlined,
  BookOutlined,
} from "@ant-design/icons";

//菜单
const allRouters = [
  {
    label: "首页",
    key: "/",
    icon: <HomeOutlined />,
  },
  {
    label: "计划管理",
    key: "/Plan",
    icon: <FilePptOutlined />,
  },
  {
    label: "Portal管理",
    key: "/Portal",
    icon: <BookOutlined />,
    children: [
      { label: "Portal列表", key: "/Portal/PortalList" },
      { label: "权重管理", key: "/Portal/Weights" },
    ],
  },
  {
    label: "配置管理",
    key: "/Setting",
    icon: <SettingOutlined />,
    children: [{ label: "Portal配置", key: "/Setting/Portal" }],
  },
  // {
  //   label: "收益明细",
  //   key: "/Income",
  //   icon: <MoneyCollectOutlined />,
  // },
];

const allStatus = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "排期中",
  },
  {
    value: 2,
    label: "投放中",
  },
  {
    value: 3,
    label: "已完成",
  },
  {
    value: 4,
    label: "已关闭",
  },
];

const wifiDuration = [
  {
    value: 30,
    label: "0.5小时",
  },
  {
    value: 60,
    label: "1小时",
  },
  {
    value: 90,
    label: "1.5小时",
  },
  {
    value: 120,
    label: "2小时",
  },
  {
    value: 150,
    label: "2.5小时",
  },
  {
    value: 180,
    label: "3小时",
  },
];

const allPortalType = [
  {
    value: "",
    label: "全部",
  },
  {
    value: 1,
    label: "竖版视频",
  },
  {
    value: 2,
    label: "竖版大图",
  },
  {
    value: 3,
    label: "图文广告",
  },
  {
    value: 4,
    label: '外链网站'
  },
];

const btnTxtList = [
  {
    value: "See More",
    label: "See More",
  },
  {
    value: "Shop Now",
    label: "Shop Now",
  },
  {
    value: "DownLoad",
    label: "DownLoad",
  },
];

const Unit = "￥";

export { allRouters, allStatus, wifiDuration, allPortalType, btnTxtList, Unit };
