import React, { useState, useEffect } from "react";
import { Form, Upload, ColorPicker, message, Button, Modal, Space } from "antd";
import {
  FileImageOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import FormInputItem from "@/components/FormInputItem";
import { useRequest } from "ahooks";
import { uploadVideoImage } from "@/api/portal";
import DurationFormSelect from "./DurationFormSelect";

function ImageForm(props) {
  console.log("ImageForm", props);
  const { resource, type } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [uri, setUri] = useState("");

  const imageUpload = useRequest(
    (params) => {
      return uploadVideoImage(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("image upload onFinish", res);
        const result = res.data.data;
        if (resource) {
          console.log(uri);
          const _uri = [...uri].filter((item) => !item.originFileObj);
          const newUriList = [..._uri, ...result.uris];
          setUri(newUriList);
          console.log(newUriList);
          const arr = renderUriArr(newUriList);
          props.onImageUploaded({
            host: result.host,
            uris: arr,
          });
        } else {
          setUri(result.uris);
          props.onImageUploaded(result);
        }
        message.success("上传成功");
      },
      onError: (error) => {
        console.log("image upload error", error);
      },
    }
  );

  const renderUriArr = (list) => {
    const arr = [];
    list.forEach((item) => {
      if (item.source) {
        arr.push(item.source);
      } else {
        arr.push(item);
      }
    });
    return arr;
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    //console.log("handlePreview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
    setPreviewOpen(true);
  };

  const handleUploadImage = () => {
    console.log("上传视频封面", fileList);
    if (resource) {
      //编辑时判断是否有新上传的图片
      const newUpload = fileList.filter((item) => item.originFileObj);
      if (!newUpload.length) {
        message.error("请上传图片");
        return;
      }
    }
    const formData = new FormData();
    fileList.forEach((item, i) => {
      formData.append("files[]", item.originFileObj);
    });
    imageUpload.run(formData);
  };

  //封面
  const posterProps = {
    accept: "image/png, image/jpeg, image/jpg",
    maxCount: 3,
    multiple: true,
    listType: "picture-card",
    showUploadList: resource ? true : fileList.length,
    onPreview: (file) => {
      //console.log("onPreview", file);
      handlePreview(file);
    },
    onRemove: (file) => {
      console.log("on remove", file);
      const index = fileList.findIndex(
        (item) => item === file.source || item.source === file.source
      );
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      console.log(index, newFileList);
      const arr = renderUriArr(newFileList);
      setFileList(newFileList);
      setUri(arr);
      if (resource && !file.originFileObj) {
        //console.log('arrrrrr', arr)
        props.onImageUploaded({
          host: resource.resource.poster.host,
          uris: arr,
        });
      }
    },
    beforeUpload: (file) => {
      console.log("beforeUpload", file, fileList);
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('请上传JPG/PNG格式文件!');
      }
      const isLt = file.size / 1024 > 400;
      if (isLt) {
        message.error("文件大小超出400kb，请重新上传!");
        console.log("文件大小超出400kb，请重新上传!")
      }
      return isJpgOrPng && isLt;
      // if (file.size / 1024 > 400) {
      //   message.error("文件大小超出400kb，请重新上传!");
      //   setFileList([]);
      //   return;
      // }
      // setFileList([...fileList, file]);
      // return false;
    },
    onChange: (file) => {
      console.log("onchange", file);
      const oList = file.fileList.filter(item=>item.status !== 'error')
      console.log(oList)
      setFileList(oList);
    },
  };

  useEffect(() => {
    if (resource) {
      console.log("source change", resource);
      setUri(resource.resource.poster.uris);
      setFileList(resource.resource.poster.uris);
    }
  }, [resource]);

  return (
    <>
      {!type || type === 1 ? (
        <Form.Item label="广告图片" extra="可添加1-3张图片">
          <Form.Item
            name="pics"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
            rules={[
              {
                required: true,
                message: "请上传广告图片",
              },
            ]}
          >
            <Upload.Dragger {...posterProps} style={{ marginBottom: 12 }}>
              <p className="ant-upload-drag-icon">
                <FileImageOutlined />
              </p>
              <p className="ant-upload-text">
                {"（推荐尺寸：720px X 1280px）"}
              </p>
              <p className="ant-upload-hint">{"JPG/JPEG/PNG，小于400KB"}</p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            {uri.length >= 3 ? (
              <></>
            ) : (
              <Form.Item style={{ marginTop: 20 }}>
                <Button
                  type="primary"
                  onClick={handleUploadImage}
                  disabled={fileList.length === 0}
                  loading={imageUpload.loading}
                >
                  {imageUpload.loading ? "上传中" : "开始上传"}
                </Button>
              </Form.Item>
            )}
          </Form.Item>
        </Form.Item>
      ) : (
        <Form.Item label="图片链接">
          <Form.List name="sourceLink">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      width: '100%',
                    }}
                    align="baseline"
                  >
                    <FormInputItem
                      style={{width: 336}}
                      {...restField}
                      name={[name, "posterLink"]}
                      message="请输入图片链接"
                      placeholder="请输入图片链接"
                    />
                    <FormInputItem
                      label="跳转链接"
                      style={{width: 340}}
                      {...restField}
                      name={[name, "redirectLink"]}
                      message="请输入跳转链接"
                      placeholder="请输入跳转链接"
                    />
                    <CloseOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                {fields.length >= 3 ? (
                  <></>
                ) : (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      添加图片URL（可添加1-3组链接， 推荐尺寸：720px X 1280px）
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </Form.Item>
      )}

      <DurationFormSelect />

      <FormInputItem
        name="desc"
        label="文案"
        message="请输入文案"
        placeholder="请输入文案"
        maxcount={30}
      />
      <Form.Item name="btnColor" label="按钮颜色">
        <ColorPicker />
      </Form.Item>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt=""
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}

export default ImageForm;
