import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Form,
  Breadcrumb,
  message,
  Radio,
  Checkbox,
  DatePicker,
  TimePicker,
  Switch,
  InputNumber,
  Spin,
} from "antd";

import dayjs from "dayjs";

import FormInputItem from "@/components/FormInputItem";
import PortalSelect from "@/pages/Plan/Components/PortalSelect";
import { useRequest } from "ahooks";
import store from "@/store";
import qs from "qs";
import { planEdit, planDetail } from "@/api/plan";
import Preview from "./Components/Preview";
const { RangePicker } = DatePicker;

const CheckboxGroup = Checkbox.Group;

const dates = [
  { value: 1, label: "长期投放" },
  { value: 2, label: "指定日期" },
  { value: 3, label: "闲时投放" },
];

const times = [
  { value: 1, label: "全天" },
  { value: 2, label: "指定时间" },
];

const budgetType = [
  { value: 2, label: "CPM" },
  { value: 1, label: "CPC" },
];

const formItemLayout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 17,
  },
};

function EditPlan(props) {
  const { allProduct, allCountry } = store;
  const { search } = props.location;
  const source = qs.parse(search.replace(/^\?/, ""));
  const planId = source.planId;
  const formRef = useRef();
  const [dateType, setDateType] = useState(null);
  const [timeType, setTimeType] = useState(null);
  const [curPortal, setCurPortal] = useState(null);
  const [prevData, setPrevData] = useState({});
  const [models, setModels] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [detail, setDetail] = useState(null);
  const [allModels, setAllModels] = useState([]);

  const { loading } = useRequest(
    () => {
      return planDetail({ _url: "/" + planId });
    },
    {
      onSuccess: (res, params) => {
        console.log("detail onFinish", res);
        const result = res.data.data;
        const dateValue = result.startDate ? 2 : 1;
        const timeValue = result.startTime ? 2 : 1;
        const arr = [];
        const countryList = [];
        result.models.forEach((item) => {
          arr.push(item.model + ":" + item.countryCode);
          countryList.push(item.country);
        });
        if (result.dateType === 3) {
          setCountrys([result.country]);
        } else {
          setCountrys(countryList);
        }
        result.models = arr;

        setDateType(result.dateType);
        setTimeType(timeValue);
        result.date = dateValue;
        result.times = timeValue;
        result.datePicker = result.startDate
          ? [dayjs(result.startDate), dayjs(result.endDate)]
          : [];
        result.startTime = result.startTime
          ? dayjs(result.startTime, "HH:mm:ss")
          : "";
        result.endTime = result.endTime
          ? dayjs(result.endTime, "HH:mm:ss")
          : "";
        setDetail(result);
        setPrevData(result);

        setCurPortal(result.portal);
        console.log(result);
      },
      onError: (error) => {
        console.log("detail error", error);
      },
    }
  );

  const onEdit = useRequest(
    (params) => {
      return planEdit(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        message.success("编辑成功");
        setTimeout(() => {
          props.history.goBack();
        }, 500);
      },
      onError: (error) => {
        console.log("edit error", error);
      },
    }
  );

  const onFinish = (values) => {
    let params = {
      _url: "/" + planId,
      ...values,
      portalId: curPortal.portalId,
    };

    if (values.datePicker) {
      params.startDate = dayjs(values.datePicker[0]).format("YYYY-MM-DD");
      params.endDate = dayjs(values.datePicker[1]).format("YYYY-MM-DD");
    }
    if (values.startTime) {
      params.startTime = dayjs(values.startTime).format("HH:mm:ss");
    }
    if (values.endTime) {
      params.endTime = dayjs(values.endTime).format("HH:mm:ss");
    }
    if (values.date === 1) {
      params.startDate = "";
      params.endDate = "";
    }
    if (values.times === 1) {
      params.startTime = "";
      params.endTime = "";
    }
    console.log("表单提交: ", params);
    onEdit.run(params);
  };

  const onValuesChange = (c, a) => {
    // console.log(c)
    // console.log(a)
    setPrevData(a);
  };

  const dateChange = (e) => {
    console.log(e);
    setDateType(e.target.value);
    setCountrys([]);
    formRef.current.setFieldValue("models", null);
    if (e.target.value === 3) {
      setModels([]);
      formRef.current.setFieldValue("countryCode", []);
    }
  };

  const timeChange = (e) => {
    console.log(e);
    setTimeType(e.target.value);
  };

  const countryChange = (e) => {
    console.log(e, allCountry);
    const d = allCountry.find((item) => item.countryCode === e.target.value);
    console.log(d);
    setCountrys([d]);
  };

  const modelChange = (e) => {
    console.log(e, allProduct);
    //取交集 过滤出选择过的model
    const d = [...allProduct].filter((p) =>
      [...e].some((m) => m === p.model + ":" + p.countryCode)
    );
    setModels(d);
    const c = [];
    d.forEach((item) => {
      c.push(item.country);
    });
    setCountrys(c);
  };

  useEffect(() => {
    if (detail) {
      let modelOptions = [],
        planModels = [];
      allProduct.forEach((item) => {
        modelOptions.push({
          label: item.label,
          value: item.model + ":" + item.countryCode,
        });

        if (
          detail.models.some((m) => m === item.model + ":" + item.countryCode)
        ) {
          planModels.push(item);
        }
      });
      setAllModels(modelOptions);
      setModels(planModels);
    }
  }, [allProduct, detail]);

  return (
    <div className="add-ope">
      <Breadcrumb
        items={[
          {
            title: (
              <span
                className="nav-links"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                计划详情
              </span>
            ),
          },
          {
            title: "编辑计划",
          },
        ]}
      />
      <Spin spinning={loading} size="large" tip="Loading...">
        <div className="ope-wrap">
          {detail && (
            <>
              <Form
                ref={formRef}
                scrollToFirstError={true}
                onValuesChange={onValuesChange}
                name="validate_other"
                {...formItemLayout}
                onFinish={onFinish}
                initialValues={{
                  ...detail,
                }}
              >
                <FormInputItem
                  name="name"
                  label="计划名称"
                  message="请输入计划名称"
                  placeholder="请输入计划名称"
                  maxcount={60}
                />

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "请选择投放日期",
                    },
                  ]}
                  name="dateType"
                  label="投放日期"
                >
                  <Radio.Group buttonStyle="solid" onChange={dateChange}>
                    {dates.map((item, index) => {
                      return (
                        <Radio.Button key={index} value={item.value}>
                          {item.label}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>

                {dateType !== 3 ? (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "请选择硬件型号",
                      },
                    ]}
                    name="models"
                    label="硬件型号"
                  >
                    <CheckboxGroup onChange={modelChange} options={allModels} />
                  </Form.Item>
                ) : (
                  <></>
                )}

                {dateType !== 3 ? (
                  <>
                    {dateType === 2 ? (
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "请选择投放日期",
                          },
                        ]}
                        name="datePicker"
                        label="选择日期"
                      >
                        <RangePicker />
                      </Form.Item>
                    ) : (
                      <></>
                    )}

                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "请选择投放时间",
                        },
                      ]}
                      name="times"
                      label="投放时间"
                    >
                      <Radio.Group buttonStyle="solid" onChange={timeChange}>
                        {times.map((item, index) => {
                          return (
                            <Radio.Button key={index} value={item.value}>
                              {item.label}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </Form.Item>

                    {timeType === 2 ? (
                      <>
                        <Form.Item
                          rules={[
                            {
                              type: "object",
                              required: true,
                              message: "请选择投放开始时间",
                            },
                          ]}
                          name="startTime"
                          label="开始时间"
                        >
                          <TimePicker />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              type: "object",
                              required: true,
                              message: "请选择投放结束时间",
                            },
                          ]}
                          name="endTime"
                          label="结束时间"
                        >
                          <TimePicker />
                        </Form.Item>
                      </>
                    ) : (
                      <></>
                    )}
                    <Form.Item
                      extra={"达到预算目标，自动下线Portal广告"}
                      name="autoClose"
                      label="预算开关"
                      valuePropName="checked"
                    >
                      <Switch disabled />
                    </Form.Item>

                    {detail.autoClose == 1 ? (
                      <>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "请选择预算方式",
                            },
                          ]}
                          name="type"
                          label="预算方式"
                        >
                          <Radio.Group disabled buttonStyle="solid">
                            {budgetType.map((item, index) => {
                              return (
                                <Radio.Button key={index} value={item.value}>
                                  {item.label}
                                </Radio.Button>
                              );
                            })}
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                          name="count"
                          rules={[
                            {
                              required: true,
                              message: "请输入预算目标",
                            },
                          ]}
                          label="预算目标"
                        >
                          <InputNumber
                            disabled
                            style={{ width: 220 }}
                            placeholder="请输入达到多少次展示"
                            min={0}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* <Form.Item
                      name="price"
                      rules={[
                        {
                          required: true,
                          message: "请输入广告单价",
                        },
                      ]}
                      label="广告单价"
                    >
                      <InputNumber
                        disabled
                        placeholder="广告单价"
                        min={0}
                        addonAfter={Unit}
                      />
                    </Form.Item> */}
                  </>
                ) : (
                  <>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "请选择国家",
                        },
                      ]}
                      name="countryCode"
                      label="国家"
                    >
                      <Radio.Group onChange={countryChange}>
                        {allCountry.map((item, index) => {
                          return (
                            <Radio key={index} value={item.value}>
                              {item.label}
                            </Radio>
                          );
                        })}
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}

                <Form.Item label="Portal选择">
                  {/* <Button
                    type="link"
                    style={{ marginBottom: 10 }}
                    onClick={() => {
                      props.history.push("/Portal/AddPortal");
                    }}
                  >
                    创建Portal
                  </Button> */}
                  <PortalSelect curPortal={curPortal} type={2} />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    span: 12,
                    offset: 3,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={onEdit.loading}
                  >
                    提交
                  </Button>
                  <Button
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                      props.history.goBack();
                    }}
                  >
                    返回
                  </Button>
                </Form.Item>
              </Form>
              <Preview
                dateType={dateType}
                data={prevData}
                models={models}
                countrys={countrys}
                curPortal={curPortal}
              />
            </>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default EditPlan;
