import React from "react";
import { Link, useParams } from "react-router-dom";
import { Result, Button } from "antd";

function ErrorPage(props) {
  let params = useParams();
  console.log(params.code);
  return (
    <Result
      status="404"
      title="404"
      style={{
        background: "none",
      }}
      subTitle="页面不存在."
      extra={
        <Link to="/">
          <Button type="primary">返回首页</Button>
        </Link>
      }
    />
  );
}

export default ErrorPage;
