import React, { useState } from "react";

import { useRequest } from "ahooks";
import { indexStat } from "@/api";
import { Card, Row, Col, Statistic, Progress } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import CardLineArea from "./CardLineArea";
import dayjs from "dayjs";
function Overview() {
  const [data, setData] = useState(null);
  const [cpcData, setCpcData] = useState(null);
  const [cpmData, setCpmData] = useState(null);
  const { loading } = useRequest(
    () => {
      return indexStat();
    },
    {
      onSuccess: (res) => {
        console.log("首页汇总", res);
        const result = res.data.data;
        setData(result);
        const cpcArr = [];
        const cpmArr = [];
        result.event_date_stat.forEach((item) => {
          cpcArr.push({
            statDate: item.statDate,
            count: Number(item.cpc_count),
          });
          cpmArr.push({
            statDate: item.statDate,
            count: Number(item.cpm_count),
          });
        });
        setCpcData(cpcArr);
        setCpmData(cpmArr);
      },
      onError: (error) => {
        console.log("首页汇总", error);
      },
    }
  );

  //(当前月数据-上个月数据）/上个月数据×100%=月环比
  const getQOQ = (d) => {
    let percent = 0;
    if(d.portal_current_month_count === 0){
      percent = 0
    } else if (d.portal_last_month_count === 0) {
      percent = 100;
    } else {
      percent =
        ((d.portal_current_month_count - d.portal_last_month_count) /
          d.portal_last_month_count) *
        100;
    }
    return percent;
  };

  const getClickQOQ = (d) => {
    let percent = 0;
    const curPer =
      d.cpm_current_month === 0 ? 0 : d.cpc_current_month / d.cpm_current_month;
    const lastPer =
      d.cpm_last_month === 0 ? 0 : d.cpc_last_month / d.cpm_last_month;
    //(本月点击量 /本月展示量 - 上月点击量/上月展示量)/(上月点击量/上月展示量) * 100
    if(curPer === 0){
      percent = 0
    } else if (lastPer === 0 || d.cpm_last_month === 0) {
      percent = 100;
    } else {
      percent = ((curPer - lastPer) / lastPer) * 100;
    }
    return percent;
  };

  const getTodayCount = (list) => {
    const today = dayjs().format("YYYY-MM-DD");
    const item = list.find((item) => item.statDate === today);
    if (!item) {
      return { cpc_count: 0, cpm_count: 0 };
    }
    return item;
  };
  if (!data) {
    return <Card loading={loading} />;
  }
  return (
    <Row gutter={16} className="overview-row">
      <Col span={6}>
        <Card
          loading={loading}
          title="广告总数"
          extra={<Statistic value={data.portal_total_count} />}
          actions={[
            <div className="actions">
              <span>日均新增</span>
              <span>
                {data.portal_current_month_count - data.portal_last_month_count}
              </span>
            </div>,
          ]}
          bordered={false}
        >
          <Statistic
            title="月环比"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            value={getQOQ(data)}
            precision={2}
            valueStyle={{
              color: getQOQ(data) >= 0 ? "#cf1322" : "#3f8600",
              marginLeft: 7,
            }}
            prefix={
              getQOQ(data) >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />
            }
            suffix="%"
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card
          loading={loading}
          title="展示量"
          extra={<Statistic value={data.cpm_current_month} />}
          actions={[
            <div className="actions">
              <span>今日展示量</span>
              <span>{getTodayCount(data.event_date_stat).cpm_count}</span>
            </div>,
          ]}
          bordered={false}
        >
          <CardLineArea data={cpmData} />
        </Card>
      </Col>
      <Col span={6}>
        <Card
          loading={loading}
          title="点击量"
          extra={<Statistic value={data.cpc_current_month} />}
          actions={[
            <div className="actions">
              <span>今日点击量</span>
              <span>{getTodayCount(data.event_date_stat).cpc_count}</span>
            </div>,
          ]}
          bordered={false}
        >
          <CardLineArea data={cpcData} />
        </Card>
      </Col>
      <Col span={6}>
        <Card
          loading={loading}
          title="点击率"
          extra={
            <Statistic
              value={(data.cpm_current_month === 0 || data.cpc_current_month === 0) ? 0 : (data.cpc_current_month / data.cpm_current_month) * 100}
              precision={3}
              suffix="%"
            />
          }
          actions={[
            <Statistic
              title="月环比"
              style={{
                display: "flex",
                marginLeft: 24,
              }}
              value={getClickQOQ(data)}
              precision={2}
              valueStyle={{
                color: getClickQOQ(data) >= 0 ? "#cf1322" : "#3f8600",
                marginLeft: 7,
                fontSize: 14,
              }}
              prefix={
                getClickQOQ(data) >= 0 ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )
              }
              suffix="%"
            />,
          ]}
          bordered={false}
        >
          <Progress
            percent={(data.cpc_current_month / data.cpm_current_month) * 100}
            showInfo={false}
            size={["100%", 20]}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default Overview;
