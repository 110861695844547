import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Menu, Button } from "antd";
import StorageUtil from "@/utils/StorageUtil";
import store from "@/store";
import { logout } from "@/api/login";
import logo from "@/assets/logo.png";
import { allRouters } from "@/config/AppConfig";
import "./header.scss";

const NavFilterList = ["/Login"];
function Header(props) {
  const { userInfo, setUserInfo, setAccessToken } = store;
  const history = useHistory();
  const [current, setCurrent] = useState("/");
  const location = useLocation();
  const [flag, setFlag] = useState(false);

  const userLogout = (history) => {
    logout().then((res) => {
      console.log("logout", res);
      StorageUtil.del("mgy_portal_member_info");
      StorageUtil.del("mgy_portal_accessToken");
      setUserInfo({});
      setAccessToken(null);
      history.push("/Login");
    });
  };

  const onClick = (e) => {
    setCurrent(e.key);
    if (e.key === current) {
      return;
    }
    history.push({
      pathname: e.key,
    });
  };

  useEffect(() => {
    console.log(location.pathname);
    setCurrent(location.pathname);
    setFlag(NavFilterList.includes(location.pathname));
  }, [location.pathname]);

  if (flag) {
    return <></>;
  }
  return (
    <div className={`mgy-header`}>
      <div className="h-l">
        <div className="flexRow">
          <img className="logo" src={logo} alt="logo" />
          <div className="name">Portal广告运营</div>
        </div>

        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={allRouters}
        />
      </div>
      <div className="userInfo">
        <span>你好, {userInfo?.name}</span>
        <Button
          type="link"
          onClick={() => {
            userLogout(history);
          }}
        >
          退出登录
        </Button>
      </div>
    </div>
  );
}

export default Header;
