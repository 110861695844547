import React from "react";
import FormInputItem from "@/components/FormInputItem";
import { Form, ColorPicker } from "antd";
import DurationFormSelect from "./DurationFormSelect";

function LinkForm(props) {
  return (
    <>
      <FormInputItem
        name="link"
        label="链接地址"
        message="请填入广告外链地址"
        placeholder="请填入广告外链地址"
      />
      <FormInputItem
        name="desc"
        label="文案"
        message="请输入文案"
        placeholder="请输入文案"
        maxcount={30}
      />
      <DurationFormSelect />
      <Form.Item name="btnColor" label="按钮颜色">
        <ColorPicker />
      </Form.Item>
    </>
  );
}

export default LinkForm;
