import React, { useEffect, useState } from "react";

let timeout = null;
function LinkPortal(props) {
  const { data } = props;
  const [left, setLeft] = useState(data.resource.duration);

  useEffect(() => {
    window.clearTimeout(timeout);
    timeout = null;
    setLeft(data.resource.duration);
  }, [data.resource.duration]);

  useEffect(() => {
    const hdHt = document
      .querySelector(".header")
      .getBoundingClientRect().height;
    const btHt = document.querySelector(".bot").getBoundingClientRect().height;
    document.querySelector(".content").style.height = 550 - hdHt - btHt + "px";
    timeout = setTimeout(() => {
      if (left > 0) {
        setLeft((c) => c - 1);
      } else {
        window.clearTimeout(timeout);
        timeout = null;
      }
    }, 1000);
  }, [left]);
  return (
    <div className="portal-wrap">
      <div className="header">
        <div className="header-con">
          <div>Watch Ads for free and secured Wi-Fi connection</div>
          {left ? <div>{left}s</div> : <></>}
        </div>
      </div>
      <div className="content">
        {data.resource.link.endsWith(".mp4") ? (
          <div className="video-wrap">
            <video
              className="video"
              poster={data.resource.poster}
              autoPlay
              playsInline
              muted
              loop
              preload="metadata"
              src={data.resource.link}
            ></video>
          </div>
        ) : (
          <div className="image-wrap">
            <iframe
              className="iframes"
              src={data.resource.link}
              title="link show"
            ></iframe>
          </div>
        )}
      </div>
      <div className="bot">
        <div className="bot-con">
          <div className="desc">{data.resource.desc}</div>
          <a
            rel="noreferrer"
            href={data.target}
            target="_blank"
            className="btn"
            style={{ background: data.resource.btnColor }}
          >
            {data.button}
          </a>
        </div>
      </div>
    </div>
  );
}

export default LinkPortal;
