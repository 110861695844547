import React, { useRef } from "react";
import { Card, Row, Col, Form, Button, DatePicker, Select } from "antd";
import { allStatus } from "@/config/AppConfig";
import store from "@/store";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

function FilterBar(props) {
  const { allProduct, allCountry } = store;
  const formRef = useRef();
  const [form] = Form.useForm();
  return (
    <div className="filterBar">
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Row>
          <Col>
            <div className="box">
              <Form
                layout={"inline"}
                ref={formRef}
                form={form}
                onFinish={props.onFinish}
                initialValues={{
                  status: Number(props.status) || null,
                }}
              >
                <Row className="rows">
                  <Form.Item
                    name="countryCodes"
                    label="国家"
                    style={{ width: 500 }}
                  >
                    <Select
                      maxTagCount="responsive"
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="请选择国家"
                      options={allCountry}
                    />
                  </Form.Item>
                </Row>
                <Row className="rows">
                  <Form.Item
                    name="models"
                    label="产品型号"
                    style={{ width: 500 }}
                  >
                    <Select
                      maxTagCount="responsive"
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="请选择产品型号"
                      options={allProduct}
                    />
                  </Form.Item>
                </Row>
                <Row className="rows">
                  <Form.Item label="状态" name="status">
                    <Select placeholder="请选择" options={allStatus} />
                  </Form.Item>
                  <Form.Item name="times" label="时间">
                    <RangePicker
                      presets={[
                        {
                          label: "昨天",
                          value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
                        },
                        {
                          label: "今天",
                          value: [dayjs(), dayjs()],
                        },
                        {
                          label: "近一周",
                          value: [dayjs().add(-7, "d"), dayjs()],
                        },
                        {
                          label: "近一月",
                          value: [dayjs().add(-30, "d"), dayjs()],
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      查询
                    </Button>
                    <Button
                      htmlType="button"
                      onClick={() => {
                        formRef.current.resetFields();
                      }}
                    >
                      重置
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default FilterBar;
