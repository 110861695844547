import React from "react";

import VideoPortal from "./VideoPortal";
import ImagePortal from "./ImagePortal";
import TextPortal from "./TextPortal";
import LinkPortal from "./LinkPortal";

import "./style.scss";


function PortalPreview(props) {
  console.log(props);
  const { portals, type } = props;
  if (!portals) {
    return <div className="preview"></div>;
  }
  return (
    <div className={`preview-phone ${type ? 'pc' : ''}`}>
      {/* <h1>Portal预览</h1> */}
      <div className="wrap">
        {portals.type === 1 && <VideoPortal data={portals} />}
        {portals.type === 2 && <ImagePortal data={portals} />}
        {portals.type === 3 && <TextPortal data={portals} />}
        {portals.type === 4 && <LinkPortal data={portals} />}
      </div>
    </div>
  );
}

export default PortalPreview;
