import React, { useEffect, useState } from "react";
import { Breadcrumb, Spin, Descriptions, Button, Tag } from "antd";

import { useRequest } from "ahooks";
import qs from "qs";
import { planDetail, planStat } from "@/api/plan";
import PortalPreview from "@/components/PortalPreview/PortalPreview";
import Chart from "./Components/Chart";
import { renderTime } from "@/utils";
import TogglePlan from "../../components/TogglePlan/TogglePlan";

function PlanDetail(props) {
  const { search } = props.location;
  const source = qs.parse(search.replace(/^\?/, ""));
  const { planId, from } = source;
  const [detail, setDetail] = useState({});
  const [statData, setStatData] = useState([]);

  const { loading, run, refresh } = useRequest(
    (params) => {
      return planDetail(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("detail onFinish", res);
        setDetail(res.data.data);
        statPlan.run({ _url: "/" + planId });
      },
      onError: (error) => {
        console.log("detail error", error);
      },
    }
  );

  const statPlan = useRequest(
    (params) => {
      return planStat(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("stat onFinish", res);
        const arr = [];
        res.data.data.forEach((item) => {
          arr.push({
            time: item.statHour + " : 00",
            count: detail.type === 1 ? item.cpc : item.cpm,
            value: item.onlineDevices,
            name1: "路由器",
            name2: detail.type === 1 ? "CPC" : "CPM",
          });
        });
        setStatData(arr);
      },
      onError: (error) => {
        console.log("stat error", error);
      },
    }
  );

  const onConfirm = () => {
    refresh();
  };

  useEffect(() => {
    run({ _url: "/" + planId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  return (
    <div className="add-ope">
      <Breadcrumb
        items={[
          {
            title: (
              <span
                className="nav-links"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                {from === "home"
                  ? "首页"
                  : from === "weight"
                  ? "权重管理"
                  : "计划管理"}
              </span>
            ),
          },
          {
            title: "计划详情",
          },
        ]}
      />
      <Spin spinning={loading} size="large" tip="Loading...">
        <div className="ope-wrap">
          <div className="d-con">
            <Descriptions
              title={
                <Button
                  type="link"
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  返回
                </Button>
              }
              extra={
                <>
                  <Button
                    type="link"
                    onClick={() => {
                      props.history.push("/Plan/EditPlan?planId=" + planId);
                    }}
                  >
                    重新编辑
                  </Button>
                  <TogglePlan record={detail} onConfirm={onConfirm} />
                </>
              }
              bordered
              column={1}
            >
              <Descriptions.Item label="计划Id">
                {detail.planId}
              </Descriptions.Item>
              <Descriptions.Item label="计划名称">
                {detail.name}
              </Descriptions.Item>
              {detail.dateType === 3 ? (
                <Descriptions.Item label="地区">
                  <span style={{ marginRight: 10 }}>
                    {detail.country?.name}
                  </span>
                </Descriptions.Item>
              ) : (
                <Descriptions.Item label="地区">
                  {detail?.models?.map((item, index) => {
                    return (
                      <Tag
                        key={index}
                        style={index > 0 ? { marginTop: 5 } : ""}
                      >
                        {"【" + item.country.name + "】" + item.model}
                      </Tag>
                    );
                  })}
                </Descriptions.Item>
              )}

              <Descriptions.Item label="投放数量">
                {detail.devices_count}
              </Descriptions.Item>
              <Descriptions.Item label="计划时间">
                {renderTime(detail)}
              </Descriptions.Item>
              {detail.autoClose == 1 ? (
                <>
                  <Descriptions.Item label="预算方式">
                    {detail.type === 1 ? "CPC" : "CPM"}
                  </Descriptions.Item>
                  <Descriptions.Item label="预算目标">
                    {detail.count}次展示
                  </Descriptions.Item>
                </>
              ) : (
                <></>
              )}
              <Descriptions.Item label="PortalId">
                {detail.portalId}
              </Descriptions.Item>
              <Descriptions.Item label="选择Portal">
                {detail.portal?.name}
              </Descriptions.Item>
              <Descriptions.Item label="开始时间">
                {detail.startDate || "长期投放"} {detail.startTime || "全天"}
              </Descriptions.Item>
              <Descriptions.Item label="CPM">{detail.cpm}</Descriptions.Item>
              <Descriptions.Item label="CPC">{detail.cpc}</Descriptions.Item>
            </Descriptions>
            <Chart data={statData} />
          </div>
          <PortalPreview portals={detail.portal} />
        </div>
      </Spin>
    </div>
  );
}

export default PlanDetail;
