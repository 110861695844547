import Http from "../utils/Http";
import { omit } from "lodash";

//portal配置列表
export function getPortalList(data) {
  const result = Http({
    url: "/share-wifi/portal",
    method: "GET",
    data,
  });
  return result;
}

//权重列表
export function getWeightList(data) {
  const result = Http({
    url: "/share-wifi/plan-model",
    method: "GET",
    data,
  });
  return result;
}

//编辑权重
export function weightEdit(data) {
  const result = Http({
    url: "/share-wifi/plan-model" + data._url,
    method: "PUT",
    data: omit(data, "_url"),
  });
  return result;
}

//添加portal
export function portalAdd(data) {
  const result = Http({
    url: "/share-wifi/portal",
    method: "POST",
    data,
  });
  return result;
}

//删除portal
export function portalDel(data) {
  const result = Http({
    url: "/share-wifi/portal",
    method: "DELETE",
    data,
  });
  return result;
}

//编辑portal
export function portalEdit(data) {
  const result = Http({
    url: "/share-wifi/portal" + data._url,
    method: "PUT",
    data: omit(data, "_url"),
  });
  return result;
}

//Portal详情
export function portalDetail(data) {
  const result = Http({
    url: "/share-wifi/portal" + data._url,
    method: "GET",
    data: omit(data, "_url"),
  });
  return result;
}

//上传视频
export function uploadVideo(data) {
  const result = Http({
    url: "/upload/portal-video",
    method: "upload",
    data,
  });
  return result;
}

//上传视频封面
export function uploadVideoImage(data) {
  const result = Http({
    url: "/upload/portal-image",
    method: "upload",
    data,
  });
  return result;
}
