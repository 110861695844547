import React from "react";
import { Form, Tag } from "antd";
import dayjs from "dayjs";
const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
function Preview(props) {
  const { data, models, countrys, curPortal, dateType } = props;
  //console.log(data);
  console.log(data, models, dateType, countrys);
  const modelSum = (list) => {
    let sum = 0;
    list.forEach((item) => {
      sum += item.devices_count;
    });
    return sum;
  };

  const renderCountrys = (countrys) => {
    let arr = [];
    countrys.forEach((item) => {
      arr.push(item.name);
    });
    return Array.from(new Set(arr));
  };
  return (
    <div className="preview">
      <h1>计划目标</h1>
      <Form className="prev-con" {...formItemLayout}>
        <Form.Item label="计划名称">
          <div>{data.name}</div>
        </Form.Item>
        <Form.Item label="地区">
          <div style={{ display: "flex", flexWrap: "wrap", width: "300px" }}>
            {renderCountrys(countrys).map((item, index) => {
              return (
                <Tag key={index} style={{ marginBottom: 5 }}>
                  {item}
                </Tag>
              );
            })}
          </div>
        </Form.Item>
        {dateType === 3 ? (
          <></>
        ) : (
          <Form.Item label="投放数量">
            <div>{modelSum(models)}台</div>
          </Form.Item>
        )}

        <Form.Item label="日期">
          <div>
            {data.dateType === 3
              ? "闲时投放"
              : data.dateType === 1
              ? "长期投放"
              : data.datePicker?.length
              ? dayjs(data.datePicker[0]).format("YYYY-MM-DD") +
                " 至 " +
                dayjs(data.datePicker[1]).format("YYYY-MM-DD")
              : ""}
          </div>
        </Form.Item>
        <Form.Item label="时间">
          <div>
            {data.dateType === 3
              ? "闲时投放"
              : data.times === 1
              ? "全天"
              : data.startTime
              ? dayjs(data.startTime).format("HH:mm:ss") +
                " 至 " +
                dayjs(data.endTime).format("HH:mm:ss")
              : ""}
          </div>
        </Form.Item>
        {data.autoClose == 1 ? (
          <>
            <Form.Item label="预算方式">
              <div>
                {data.dateType === 3
                  ? "无"
                  : data.type === 1
                  ? "CPC"
                  : data.type === 2
                  ? "CPM"
                  : ""}
              </div>
            </Form.Item>
            <Form.Item label="预算目标">
              <div>{data.dateType === 3 ? "无" : data.count}</div>
            </Form.Item>
          </>
        ) : (
          <></>
        )}

        <Form.Item label="Portal选择">
          <div>{curPortal?.name}</div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Preview;
