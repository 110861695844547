import React from "react";
import { DualAxes } from "@ant-design/plots";
import { Empty } from "antd";
function Chart(props) {
  // const data = [
  //   {
  //     time: "2019-03",
  //     value: 350,
  //     count: 800,
  //     name1: '路由器',
  //     name2: 'CPM'
  //   },
  //   {
  //     time: "2019-04",
  //     value: 900,
  //     count: 600,
  //     name1: '路由器',
  //     name2: 'CPM'
  //   },
  //   {
  //     time: "2019-05",
  //     value: 300,
  //     count: 400,
  //     name1: '路由器',
  //     name2: 'CPM'
  //   },
  //   {
  //     time: "2019-06",
  //     value: 450,
  //     count: 380,
  //     name1: '路由器',
  //     name2: 'CPM'
  //   },
  //   {
  //     time: "2019-07",
  //     value: 470,
  //     count: 220,
  //     name1: '路由器',
  //     name2: 'CPM'
  //   },
  // ];
  const { data } = props;
  console.log(data);
  const config = {
    data: [data, data],
    xField: "time",
    yField: ["value", "count"],
    geometryOptions: [
      {
        geometry: "column",
        seriesField: "name1",
      },
      {
        geometry: "line",
        seriesField: "name2",
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
  };
  if (!data.length) {
    return <Empty />;
  }
  return <DualAxes {...config} />;
}

export default Chart;
