import React from "react";
import { Form, Select } from "antd";

const arr = [
  {
    label: "10秒",
    value: 10,
  },
  {
    label: "15秒",
    value: 15,
  },
  {
    label: "20秒",
    value: 20,
  },
  {
    label: "30秒",
    value: 30,
  },
];

function DurationFormSelect(props) {
  const { filedName } = props;
  return (
    <Form.Item label="展示时间" name={filedName || "duration"}>
      <Select placeholder="请选择" options={arr} />
    </Form.Item>
  );
}

export default DurationFormSelect;
