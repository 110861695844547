import React, { useState, useEffect } from "react";
import { Button, Breadcrumb } from "antd";
import WeightFilterBar from "./Components/WeightFilterBar";
import { pickBy } from "lodash";
import { getWeightList, weightEdit } from "@/api/portal";
import { useRequest } from "ahooks";
import CTable from "@/components/CTable";
import EditableRow from "@/components/EditableRow/EditableRow";
import EditableCell from "@/components/EditableCell/EditableCell";
import { renderTime, renderStatus } from "@/utils";
import store from "@/store";

function Weights(props) {
  const { allProduct } = store;
  const [data, setData] = useState([]);
  const [filterForm, setFilterForm] = useState({});
  const [serverTime, setServerTime] = useState(null);
  const [defaultModel, setDefaultModel] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  //权重列表api
  const { loading, run } = useRequest(
    (params) => {
      if (!params.model) {
        params.model = defaultModel;
      }
      return getWeightList(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("portal plan list onFinish", res);
        const result = res.data.data;
        setPagination({
          current: result.mate.page,
          total: result.mate.total,
          pageSize: result.mate.pageSize,
        });
        setData(result.list);
        setServerTime(result.serverTime);
      },
      onError: (error) => {
        console.log("portal plan list error", error);
      },
    }
  );

  //修改权重api
  const weightModify = useRequest(
    (params) => {
      return weightEdit(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("weight modify onFinish", res);
        const p = {
          ...filterForm,
          ...{ limit: pagination.pageSize, page: pagination.current },
        };
        run(p);
      },
      onError: (error) => {
        console.log("weight modify error", error);
      },
    }
  );

  const columns = [
    {
      title: "型号",
      dataIndex: "model",
      render: (_, record) => {
        return "【" + record.country.name + "】" + record.model;
      },
    },
    {
      title: "Portal名称",
      dataIndex: "portal",
      render: (_, record) => record.portal.name,
    },
    {
      title: "计划名称",
      dataIndex: "plan",
      render: (_, record) => record.plan.name,
    },
    {
      title: "计划时间",
      dataIndex: "startDate",
      render: (_, record) => renderTime(record.plan),
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (_, record) => (
        <div
          dangerouslySetInnerHTML={{
            __html: renderStatus(record.plan, serverTime),
          }}
        ></div>
      ),
    },
    {
      title: "权重",
      editable: true,
      dataIndex: "weight",
      width: 120,
    },
    {
      title: "操作",
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              console.log("modify");
              props.history.push(
                "/Plan/PlanDetail?from=weight&planId=" + record.planId
              );
            }}
          >
            编辑
          </Button>
        </>
      ),
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (row) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    //console.log(newData);
    //console.log(item, row);
    if (item.weight !== row.weight) {
      weightModify.run({
        _url: "/" + row.id,
        weight: row.weight,
      });
    }

    //setDataSource(newData);
  };

  const eidtColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        type: 1, // 1：数字输入框  2：文本输入框
      }),
    };
  });

  const onFinish = (values) => {
    const filterValues = pickBy({ ...values });
    console.log("pickBy", filterValues);
    if (filterValues.times && filterValues.times.length) {
      filterValues.stateDate = filterValues.times[0].format("YYYY-MM-DD");
      filterValues.endDate = filterValues.times[1].format("YYYY-MM-DD");
    }
    setFilterForm(filterValues);
    const params = {
      ...filterValues,
      ...{ limit: pagination.pageSize, page: 1 },
    };
    run(params);
  };

  const handleTableChange = (newPagination) => {
    console.log(newPagination);
    setData([]);
    setPagination(newPagination);
    const params = {
      ...filterForm,
      ...{ limit: newPagination.pageSize, page: newPagination.current },
    };
    run(params);
  };

  //首次加载
  useEffect(() => {
    const params = {
      ...{ limit: pagination.pageSize, page: pagination.current },
    };
    if (allProduct.length) {
      params.model = allProduct[0].value;
      setDefaultModel(allProduct[0].value);
    }
    run(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProduct]);

  return (
    <div className="plan-list">
      <Breadcrumb
        items={[
          {
            title: "Portal管理",
          },
          {
            title: "权重管理",
          },
        ]}
      />
      <WeightFilterBar
        onFinish={onFinish}
        allProduct={allProduct}
        model={defaultModel}
        {...props}
      />
      <CTable
        components={components}
        rowClassName={() => "editable-row"}
        columns={eidtColumns}
        dataSource={data}
        loading={loading}
        size="middle"
        sticky={true}
        onChange={handleTableChange}
        pagination={pagination}
        rowKey={(record) => record.id}
      />
    </div>
  );
}

export default Weights;
