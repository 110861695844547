import React, { useRef } from "react";
import { Card, Row, Col, Form, Button, DatePicker, Select } from "antd";
import { allPortalType } from "@/config/AppConfig";
const { RangePicker } = DatePicker;

function FilterBar(props) {
  const formRef = useRef();
  const [form] = Form.useForm();
  return (
    <div className="filterBar">
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Row>
          <Col>
            <div className="box">
              <Form
                layout={"inline"}
                ref={formRef}
                form={form}
                onFinish={props.onFinish}
              >
                <Row className="rows">
                  <Form.Item label="类型" name="type">
                    <Select placeholder="请选择" options={allPortalType} />
                  </Form.Item>
                  <Form.Item name="times" label="时间">
                    <RangePicker />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      查询
                    </Button>
                    <Button
                      htmlType="button"
                      onClick={() => {
                        formRef.current.resetFields();
                      }}
                    >
                      重置
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default FilterBar;
