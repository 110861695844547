import { Spin } from "antd";

function PageLoading() {
  return (
    <div style={{ paddingTop: 100, textAlign: "center" }}>
      <Spin size="large" />
    </div>
  );
}

export default PageLoading;
