import React from "react";
import { Button, Popconfirm } from "antd";
import { useRequest } from "ahooks";
import { planEdit } from "@/api/plan";
import { weightEdit } from "@/api/portal";

function TogglePlan(props) {
  const { record, onConfirm, type } = props;

  //关闭/开启计划
  const togglePlan = useRequest(
    (params) => {
      return planEdit(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("plan edit onFinish", res);
        onConfirm();
      },
      onError: (error) => {
        console.log("plan edit error", error);
      },
    }
  );

  //关闭/开启portal权重
  const togglePortalPlan = useRequest(
    (params) => {
      return weightEdit(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("portal plan edit onFinish", res);
        onConfirm();
      },
      onError: (error) => {
        console.log("portal plan edit error", error);
      },
    }
  );

  const onToggle = (record) => {
    console.log(record);
    if (type === 1) {
      const status = record.status === 1 ? 0 : 1;
      togglePortalPlan.run({ _url: "/" + record.id, status });
    } else {
      const status = record.status === 4 ? 0 : 4;
      togglePlan.run({ _url: "/" + record.planId, status });
    }
  };
  //计划
  if (!type) {
    return (
      <>
        {record.status !== 4 ? (
          <Popconfirm title="是否确认关闭?" onConfirm={() => onToggle(record)}>
            <Button type="link" danger loading={togglePlan.loading}>
              关闭
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm title="是否确认开启?" onConfirm={() => onToggle(record)}>
            <Button type="link" loading={togglePlan.loading}>
              开启
            </Button>
          </Popconfirm>
        )}
      </>
    );
  }

  //权重
  return (
    <>
      {record.status === 1 ? (
        <Popconfirm title="是否确认关闭?" onConfirm={() => onToggle(record)}>
          <Button type="link" danger loading={togglePortalPlan.loading}>
            关闭
          </Button>
        </Popconfirm>
      ) : (
        <Popconfirm title="是否确认开启?" onConfirm={() => onToggle(record)}>
          <Button type="link" loading={togglePortalPlan.loading}>
            开启
          </Button>
        </Popconfirm>
      )}
    </>
  );
}

export default TogglePlan;
