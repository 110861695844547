import React, { useRef, useState } from "react";
import { Card, Row, Col, Form, Button, DatePicker, Checkbox } from "antd";
import store from "@/store";
import dayjs from "dayjs";
const CheckboxGroup = Checkbox.Group;
const { RangePicker } = DatePicker;

function FilterBar(props) {
  const { allProduct, allCountry } = store;
  const formRef = useRef();
  const [form] = Form.useForm();
  const [countrys, setCountrys] = useState([]);
  const [modelList, setModelList] = useState([]);

  const countryChange = (e) => {
    const d = [...allCountry].filter((p) =>
      [...e].some((item) => item === p.countryCode)
    );
    setCountrys(d);
    //过滤models
    const list = [...allProduct].filter((p) =>
      [...e].some((item) => item === p.countryCode)
    );
    setModelList(list);
  };

  return (
    <div className="filterBar">
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Row>
          <Col>
            <div className="box">
              <Form
                layout={"inline"}
                ref={formRef}
                form={form}
                onFinish={props.onFinish}
              >
                <Row className="rows">
                  <Form.Item name="countryCodes" label="国家">
                    <CheckboxGroup
                      onChange={countryChange}
                      options={allCountry}
                    />
                  </Form.Item>
                </Row>
                <Row className="rows">
                  {countrys.length ? (
                    <Form.Item name="models" label="硬件型号">
                      <CheckboxGroup options={modelList} />
                    </Form.Item>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className="rows">
                  <Form.Item name="times" label="时间">
                    <RangePicker 
                      presets={[
                        {
                          label: "昨天",
                          value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],
                        },
                        {
                          label: "今天",
                          value: [dayjs(), dayjs()],
                        },
                        {
                          label: "近一周",
                          value: [dayjs().add(-7, 'd'), dayjs()],
                        },
                        {
                          label: "近一月",
                          value: [dayjs().add(-30, 'd'), dayjs()],
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      查询
                    </Button>
                    <Button
                      htmlType="button"
                      onClick={() => {
                        formRef.current.resetFields();
                      }}
                    >
                      重置
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default FilterBar;
