import { useRequest } from "ahooks";
import store from "@/store/index";
import { profile } from "@/api/login";
import StorageUtil from "@/utils/StorageUtil";
import { useHistory } from "react-router-dom";
import { getAllCountry, getAllProduct } from "@/api/config";

export function useConfig() {
  const { setUserInfo, setAllCountry, setAllProduct } = store;

  let history = useHistory();

  //个人信息
  const getProfile = useRequest(
    () => {
      return profile();
    },
    {
      manual: true,
      onSuccess: (result, params) => {
        //console.log("个人信息 onFinish", result);
        StorageUtil.set("mgy_portal_member_info", result.data.data);
        setUserInfo(result.data.data);

        if (history && history.location.pathname === "/Login") {
          //console.log(history.location.pathname);
          history.push("/");
        }
      },
      onError: (error) => {
        console.log("个人信息 runAsync error", error);
      },
    }
  );

  //全部国家
  const _getAllCountry = useRequest(
    () => {
      return getAllCountry();
    },
    {
      manual: true,
      onSuccess: (result, params) => {
        //console.log("全部国家 onFinish", result);
        result.data.data.list.forEach((item) => {
          item.label = item.name;
          item.value = item.countryCode;
        });
        setAllCountry(result.data.data.list);
      },
      onError: (error) => {
        console.log("全部国家 error", error);
      },
    }
  );

  //全部产品
  const _getAllProduct = useRequest(
    () => {
      return getAllProduct();
    },
    {
      manual: true,
      onSuccess: (result, params) => {
        console.log("全部产品 onFinish", result);
        result.data.data.list.forEach((item) => {
          item.label = item.label =
            "【" +
            item.country.name +
            "】" +
            item.model +
            " (" +
            item.devices_count +
            "台)";
          item.value = item.modelId;
          item.title = item.model;
        });
        setAllProduct(result.data.data.list);
      },
      onError: (error) => {
        console.log("全部产品 error", error);
      },
    }
  );

  const init = () => {
    getProfile.run();
    _getAllCountry.run();
    _getAllProduct.run();
  };

  return [init];
}
