import Http from "../utils/Http";
import { omit } from "lodash";

//全部产品型号
export function getAllProduct(data) {
  const result = Http({
    url: "/share-wifi/all_models",
    method: "GET",
    data,
  });
  return result;
}

//全部国家
export function getAllCountry(data) {
  const result = Http({
    url: "/all_country?hasModel=1",
    method: "GET",
    data,
  });
  return result;
}

//全局配置列表
export function getGlobalConfig(data) {
  const result = Http({
    url: "/share-wifi/global-config",
    method: "GET",
    data,
  });
  return result;
}

//添加全局配置
export function globalConfigAdd(data) {
  const result = Http({
    url: "/share-wifi/global-config",
    method: "POST",
    data,
  });
  return result;
}

//删除全局配置
export function globalConfigDel(data) {
  const result = Http({
    url: "/share-wifi/global-config",
    method: "DELETE",
    data,
  });
  return result;
}
//编辑全局配置
export function globalConfigEdit(data) {
  const result = Http({
    url: "/share-wifi/global-config" + data._url,
    method: "PUT",
    data: omit(data, "_url"),
  });
  return result;
}
