import React, { useState, useEffect } from "react";
import { Button, Breadcrumb, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FilterBar from "./Components/FilterBar";
import { pickBy } from "lodash";
import { useRequest } from "ahooks";
import CTable from "@/components/CTable";
import ImageRender from "@/components/ImageRender/ImageRender";
import { getPortalList, portalDel } from "@/api/portal";
import PortalPreview from "@/components/PortalPreview/PortalPreview";
const { confirm } = Modal;

function PortalList(props) {
  const [data, setData] = useState([]);
  const [filterForm, setFilterForm] = useState({});
  const [currentItem, setCurrentItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const { loading, run } = useRequest(
    (params) => {
      return getPortalList(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("list onFinish", res);
        const result = res.data.data;
        setPagination({
          current: result.mate.page,
          total: result.mate.total,
          pageSize: result.mate.pageSize,
        });
        setData(result.list);
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  const delPortal = useRequest(
    (params) => {
      return portalDel(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("delete onFinish", res);
        message.success("删除成功");
        const p = {
          ...filterForm,
          ...{ limit: pagination.pageSize, page: pagination.current },
        };
        run(p);
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  const onDel = (record) => {
    confirm({
      title: "确认删除?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delPortal.run(record.portalId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onView = (record) => {
    setCurrentItem(record);
    setVisible(true);
  };

  const [columns] = useState([
    {
      title: "广告Id",
      dataIndex: "portalId",
    },
    {
      title: "预览图",
      dataIndex: "pic",
      render: (_, record) => <ImageRender record={record} />,
    },
    {
      title: "名称",
      dataIndex: "name",
    },
    {
      title: "时间",
      dataIndex: "createTime",
    },
    {
      title: "类型",
      dataIndex: "type",
      render: (_, record) => renderType(record),
    },
    {
      title: "操作",
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              console.log(record);
              onView(record);
            }}
          >
            查看
          </Button>
          <Button
            type="link"
            onClick={() => {
              console.log(record);
              props.history.push(
                "/Plan/AddPlan?from=portal&portalId=" + record.portalId
              );
            }}
          >
            使用
          </Button>
          <Button
            type="link"
            onClick={() => {
              console.log(record);
              props.history.push(
                "/Portal/EditPortal?portalId=" + record.portalId
              );
            }}
          >
            编辑
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              console.log(record);
              onDel(record);
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ]);
  const renderType = (record) => {
    let txt = "";
    if (record.type === 1) {
      txt = "竖版视频";
    } else if (record.type === 2) {
      txt = "竖版大图";
    } else if (record.type === 3) {
      txt = "图文广告";
    } else {
      txt = "外链广告";
    }
    return txt;
  };

  const onFinish = (values) => {
    const filterValues = pickBy({ ...values });
    console.log("pickBy", filterValues);
    if (filterValues.times && filterValues.times.length) {
      filterValues.startDate = filterValues.times[0].format("YYYY-MM-DD");
      filterValues.endDate = filterValues.times[1].format("YYYY-MM-DD");
    }
    setFilterForm(filterValues);
    const params = {
      ...filterValues,
      ...{ limit: pagination.pageSize, page: 1 },
    };
    run(params);
  };

  const handleTableChange = (newPagination) => {
    console.log(newPagination);
    setData([]);
    setPagination(newPagination);
    const params = {
      ...filterForm,
      ...{ limit: newPagination.pageSize, page: newPagination.current },
    };
    run(params);
  };

  //首次加载
  useEffect(() => {
    const params = {
      ...{ limit: pagination.pageSize, page: pagination.current },
    };
    run(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="portal-list">
      <Breadcrumb
        items={[
          {
            title: "Portal管理",
          },
          {
            title: "Portal列表",
          },
        ]}
      />
      <FilterBar onFinish={onFinish} {...props} />
      <div className="opt-bar">
        <Button
          className="addBtn"
          type="primary"
          onClick={() => {
            console.log("add");
            props.history.push("/Portal/AddPortal");
          }}
        >
          添加Portal
        </Button>
      </div>
      <CTable
        columns={columns}
        dataSource={data}
        loading={loading}
        size="middle"
        sticky={true}
        onChange={handleTableChange}
        pagination={pagination}
        rowKey={(record) => record.portalId}
      />
      <Modal
        open={visible}
        mask={false}
        destroyOnClose
        wrapClassName="CModal"
        footer={[]}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <PortalPreview portals={currentItem} />
      </Modal>
    </div>
  );
}

export default PortalList;
