import React, { useEffect, useRef, useState } from "react";

function VideoPortal(props) {
  //console.log("video", props.data);
  const { data } = props;
  const videoRef = useRef();
  const videoSrc = data.resource.videoSrc;
  const [left, setLeft] = useState(0);
  useEffect(() => {
    const hdHt = document
      .querySelector(".header")
      .getBoundingClientRect().height;
    const btHt = document.querySelector(".bot").getBoundingClientRect().height;
    document.querySelector(".content").style.height = 550 - hdHt - btHt + "px";
    videoRef.current.addEventListener("timeupdate", function (event) {
      const duration = parseInt(this.duration);
      const currentTime = parseInt(this.currentTime);
      const result = duration - currentTime;
      setLeft(result);
      if (result <= 0) {
        //videoRef.current.control = true;
        //alert('视频已播放完')
      }
    });
  }, []);

  // useEffect(() => {
  //   console.log('视频链接变化', data.resource.videoSrc);
  // }, [data.resource.videoSrc]);
  return (
    <div className="portal-wrap">
      <div className="header">
        <div className="header-con">
          <div>Watch the video for free secure Wi-Fi connection</div>
          {left ? <div>{left}s</div> : <></>}
        </div>
      </div>
      <div className="content">
        <div className="video-wrap">
          <video
            className="video"
            poster={data.resource.poster}
            autoPlay
            ref={videoRef}
            playsInline
            muted
            loop
            preload="metadata"
            src={videoSrc}
          >
            {/* <source src={videoSrc} type="video/mp4"></source> */}
          </video>
        </div>
      </div>
      <div className="bot">
        <div className="bot-con">
          <div className="desc">{data.resource.desc}</div>
          <a
            rel="noreferrer"
            href={data.target}
            target="_blank"
            className="btn"
            style={{ background: data.resource.btnColor }}
          >
            {data.button}
          </a>
        </div>
      </div>
    </div>
  );
}

export default VideoPortal;
