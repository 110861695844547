import React, { useState, useEffect } from "react";
import { Button, Breadcrumb, Modal, message, Tag, Tooltip } from "antd";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import FilterBar from "./Components/FilterBar";
import { pickBy } from "lodash";
import { getPlanList, planDel } from "@/api/plan";
import { useRequest } from "ahooks";
import CTable from "@/components/CTable";
import Overview from "@/pages/Plan/Components/Overview";
import { renderTime, renderStatus } from "@/utils";
import TogglePlan from "@/components/TogglePlan/TogglePlan";
import qs from "qs";
const { confirm } = Modal;

function PlanList(props) {
  const { search } = props.location;
  const source = qs.parse(search.replace(/^\?/, ""));
  const status = source.status;
  const [data, setData] = useState([]);
  const [filterForm, setFilterForm] = useState({});
  const [serverTime, setServerTime] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  //计划列表api
  const { loading, run } = useRequest(
    (params) => {
      return getPlanList(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("plan list onFinish", res);
        const result = res.data.data;
        setPagination({
          current: result.mate.page,
          total: result.mate.total,
          pageSize: result.mate.pageSize,
        });
        setData(result.list);
        setServerTime(result.serverTime);
      },
      onError: (error) => {
        console.log("plan list error", error);
      },
    }
  );

  const columns = [
    {
      title: "计划Id",
      width: "80px",
      dataIndex: "planId",
    },
    {
      title: "广告Id",
      width: "80px",
      dataIndex: "portalId",
    },
    {
      title: "计划名称",
      dataIndex: "name",
    },
    {
      title: "国家/型号",
      dataIndex: "models",
      render: (_, record) => {
        if (record.dateType === 3) {
          return record.country.name;
        }
        return record.models.map((item, index) => {
          return (
            <span key={index}>
              {"【" + item.country.name + "】" + item.model} <br />
            </span>
          );
        });
      },
    },
    {
      title: "计划时间",
      dataIndex: "startDate",
      render: (_, record) => renderTime(record),
    },
    {
      title: (
        <div>
          计划量&nbsp;
          <Tooltip placement="top" title="蓝色CPM；绿色CPC">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: "count",
      render: (_, record) =>
        record.autoClose === 1 ? (
          <>
            {record.type === 1 ? (
              <Tag color="green">{record.count}</Tag>
            ) : (
              <Tag color="blue">{record.count}</Tag>
            )}
          </>
        ) : (
          <>--</>
        ),
    },
    {
      title: "点击/曝光量",
      dataIndex: "cpm",
      render: (_, record) =>
        record.dateType === 3 ? "--" : record.cpc + " / " + record.cpm,
    },
    {
      title: "点击率",
      width: "120px",
      dataIndex: "cpc",
      render: (_, record) =>
        record.dateType === 3
          ? "--"
          : (record.cpc === 0
              ? 0
              : (record.cpc / record.cpm || 0) * 100
            ).toFixed(2) + "%",
    },
    {
      title: "状态",
      width: "80px",
      dataIndex: "status",
      render: (_, record) => (
        <div
          dangerouslySetInnerHTML={{
            __html: renderStatus(record, serverTime),
          }}
        ></div>
      ),
    },
    {
      title: "操作",
      width: "200px",
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              console.log("modify");
              props.history.push("/Plan/PlanDetail?planId=" + record.planId);
            }}
          >
            编辑
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              onDel(record);
            }}
          >
            删除
          </Button>
          <TogglePlan record={record} onConfirm={onConfirm} />
        </>
      ),
    },
  ];

  const delPlan = useRequest(
    (params) => {
      return planDel(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("delete onFinish", res);
        message.success("删除成功");
        const p = {
          ...filterForm,
          ...{ limit: pagination.pageSize, page: pagination.current },
        };
        run(p);
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  const onDel = (record) => {
    confirm({
      title: "删除后统计数据将会被清除，是否确认删除?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        delPlan.run(record.planId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onConfirm = () => {
    const p = {
      ...filterForm,
      ...{ limit: pagination.pageSize, page: pagination.current },
    };
    run(p);
  };

  const onFinish = (values) => {
    const filterValues = pickBy({ ...values });
    console.log("pickBy", filterValues);
    if (filterValues.times && filterValues.times.length) {
      filterValues.startDateStart = filterValues.times[0].format("YYYY-MM-DD");
      filterValues.startDateEnd = filterValues.times[1].format("YYYY-MM-DD");
    }
    setFilterForm(filterValues);
    const params = {
      ...filterValues,
      ...{ limit: pagination.pageSize, page: 1 },
    };
    run(params);
  };

  const handleTableChange = (newPagination) => {
    console.log(newPagination);
    setData([]);
    setPagination(newPagination);
    const params = {
      ...filterForm,
      ...{ limit: newPagination.pageSize, page: newPagination.current },
    };
    run(params);
  };

  //首次加载
  useEffect(() => {
    let params = {
      ...{ limit: pagination.pageSize, page: pagination.current },
    };
    if (status) {
      params.status = status;
    }
    run(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="plan-list">
      <Breadcrumb
        items={[
          {
            title: "计划管理",
          },
        ]}
      />
      <Overview />
      <FilterBar onFinish={onFinish} status={status} {...props} />
      <div className="opt-bar">
        <Button
          className="addBtn"
          type="primary"
          onClick={() => {
            console.log("add");
            props.history.push("/Plan/AddPlan");
          }}
        >
          添加计划
        </Button>
      </div>
      <CTable
        columns={columns}
        dataSource={data}
        loading={loading}
        size="middle"
        sticky={true}
        onChange={handleTableChange}
        pagination={pagination}
        rowKey={(record) => record.planId}
      />
    </div>
  );
}

export default PlanList;
