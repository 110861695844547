/*
 * http 请求工具，对axios的二次封装
 */

import axios from "axios";
import HttpConfig from "@/config/HttpConfig";
import StorageUtil from "./StorageUtil";
import { message } from "antd";

const service = axios.create({
  baseURL: HttpConfig.baseUrl,
  timeout: 60000,
  withCredentials: false,
});

// 配置Request
export const setupInterceptors = (history) => {
  service.interceptors.request.use(
    (config) => {
      //console.log('setupInterceptors', config)
      const access_token = StorageUtil.get("mgy_portal_accessToken");
      //config.headers["Content-Type"] = "application/x-www-form-urlencoded";
      //config.headers.Authorization = user?.token_type + ' ' + user?.access_token;

      config.headers.Authorization = "Bearer " + access_token;
      if (config.method.toLocaleLowerCase() === "get") {
        //console.log(typeof config.data)
        config.params = {
          ...config.data,
        };
      }
      if (config.method.toLocaleLowerCase() === "delete") {
        config.url += "/" + config.data;
      }
      if (config.method.toLocaleLowerCase() === "put") {
        //config.url += "/" + config.data;
        config.params = {
          ...config.data,
        };
      }
      if (config.method.toLocaleLowerCase() === "post") {
        config.data = {
          ...config.data,
        };
      }

      if (config.method.toLocaleLowerCase() === "upload") {
        config.method = "POST";
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // 配置Response

  service.interceptors.response.use(
    (res) => {
      // console.log('api response', res.config, res);
      if (res) {
        const { code } = res.data;
        //console.log(code);
        // token无效
        if (code === 403) {
          StorageUtil.del("mgy_portal_member_info");
          StorageUtil.del("mgy_portal_accessToken");
          history.push({
            pathname: "/Login",
          });

          return Promise.reject(res.data.message || "error");
        }
        // 无权限
        if (code === 401) {
          history.push({
            pathname: "/ErrorPage/403",
          });

          return Promise.reject(res.data.message || "error");
        }
        if (code === 10017) {
          message.error(
            res.data.data + " " + res.data.message || "error"
          );
          return Promise.reject(res.data.message || "error");
        }
        if (code !== 0) {
          message.error(res.data.message || "error");
          return Promise.reject(res.data.message || "error");
        }

        return res;
      } else {
        message.error("请求超时");
        return Promise.reject(new Error("超时"));
      }
    },
    (error) => {
      // 返回非2xx的情况
      message.error(error.message);
      console.log("http error", error);

      return Promise.reject(error);
    }
  );
};

export default service;
