import React, { useEffect } from "react";
import Router from "./Router";
import info from "../package.json";
import dayjs from 'dayjs';
import { ConfigProvider } from "antd";
import StorageUtil from "@/utils/StorageUtil";
import { useConfig } from "@/config/SystemConfig";
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

console.log(info.version);
if (process.env.REACT_APP_ENV === "production") {
  console.log = function () {};
}
console.log(process.env);
function App() {
  const [init] = useConfig();

  //加载本地用户信息和路由到状态
  useEffect(() => {
    const memberInfo = StorageUtil.get("mgy_portal_member_info");
    if (memberInfo) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfigProvider locale={zhCN}>
      <Router />
    </ConfigProvider>
  );
}

export default App;
