import Http from "../utils/Http";

//广告统计
export function indexPortal(data) {
  const result = Http({
    url: "/share-wifi/index/portal",
    method: "GET",
    data,
  });
  return result;
}

//最近计划
export function indexPlan(data) {
  const result = Http({
    url: "/share-wifi/index/plan",
    method: "GET",
    data,
  });
  return result;
}

//效果概况
export function indexStat(data) {
  const result = Http({
    url: "/share-wifi/index/stat",
    method: "GET",
    data,
  });
  return result;
}
