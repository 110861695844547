// /*
//  * LocalStorage 处理工具
//  */

const set = (key, value) => {
  return window.localStorage.setItem(key, JSON.stringify(value));
};

const get = (key) => {
  return JSON.parse(window.localStorage.getItem(key));
};

const del = (key) => {
  return window.localStorage.removeItem(key);
};

const clear = () => {
  return window.localStorage.clear();
};

const StorageUtil = {
  set,
  get,
  del,
  clear,
};
export default StorageUtil;
