import resso from "resso";
const store = resso({
  accessToken: null,
  layout: 'top',
  theme: 'light',
  userInfo: {},
  routers: [],
  allVersion: [],
  allProduct: [],
  allRole: [],
  allPlugin: [],
  allCountry: [],
  globalConfig: [],

  setAccessToken: (token) => (store.accessToken = token),
  setLayout: (layout) => (store.layout = layout),
  setTheme: (theme) => (store.theme = theme),
  setUserInfo: (info) => (store.userInfo = info),
  setRouters: (perRouters = []) => (store.routers = perRouters),
  setAllVersion: (arr = []) => (store.allVersion = arr),
  setAllProduct: (arr = []) => (store.allProduct = arr),
  setAllRole: (arr = []) => (store.allRole = arr),
  setAllPlugin: (arr = []) => (store.allPlugin = arr),
  setAllCountry: (arr = []) => (store.allCountry = arr),
  setGlobalConfig: (arr = []) => (store.globalConfig = arr),
});

export default store;
