import React, { useState, useEffect } from "react";
import { Breadcrumb, message, Select, Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CTable from "@/components/CTable";
import { useRequest } from "ahooks";
import {
  getPortalSettingList,
  portalSettingEdit,
  portalSettingDel,
} from "@/api/setting";
import { wifiDuration } from "@/config/AppConfig";
import EditableCell from "@/components/EditableCell/EditableCell";
import EditableRow from "@/components/EditableRow/EditableRow";
import store from "@/store";
import { renderCountryName } from "@/utils";

const { confirm } = Modal;

function PortalSetting(props) {
  const { allCountry } = store;
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const columns = [
    {
      title: "国家",
      dataIndex: "countryCode",
      render: (_, record) => renderCountryName(allCountry, record),
    },

    {
      title: "型号",
      dataIndex: "model",
    },
    {
      title: "投放数量",
      dataIndex: "devices_count",
    },
    {
      title: "SSID",
      dataIndex: "ssid",
      editable: true,
      width: "30%",
    },
    {
      title: "免费时长",
      dataIndex: "duration",
      render: (_, record) => {
        return (
          <Select
            style={{ width: "100px" }}
            onChange={(e) => {
              timeChange(e, record);
            }}
            value={record.duration}
            options={wifiDuration}
          />
        );
      },
    },
    {
      title: "操作",
      render: (_, record) => (
        <>
          <Button
            danger
            type="link"
            onClick={() => {
              delConfirm(record);
            }}
          >
            删除
          </Button>
        </>
      ),
    },
  ];

  const delConfirm = (item) => {
    console.log("delete", item);
    confirm({
      title: "确认删除?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        console.log("OK");
        delData.run(item.id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const timeChange = (e, record) => {
    console.log(e, record);
    const params = {
      _url: "/" + record.id,
      duration: e,
    };
    console.log(params);
    editData.run(params);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (row) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    //console.log(newData);
    //console.log(item, row);
    if (item.ssid !== row.ssid) {
      editData.run({
        _url: "/" + row.id,
        ssid: row.ssid,
      });
    }
  };

  const eidtColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        type: 2, // 1：数字输入框  2：文本输入框
      }),
    };
  });

  //列表
  const getData = useRequest(
    () => {
      return getPortalSettingList();
    },
    {
      manual: true,
      onSuccess: (res) => {
        console.log("列表", res);
        const result = res.data.data;
        setData(result.list);
      },
      onError: (error) => {
        console.log("列表 error", error);
      },
    }
  );

  //编辑
  const editData = useRequest(
    (params) => {
      return portalSettingEdit(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("edit onFinish", res);
        message.success("编辑成功");
        getData.refresh();
      },
      onError: (error) => {
        console.log("edit error", error);
      },
    }
  );

  //删除
  const delData = useRequest(
    (params) => {
      return portalSettingDel(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("delete onFinish", res);
        getData.refresh();
      },
      onError: (error) => {
        console.log("delete error", error);
      },
    }
  );

  const handleTableChange = (newPagination) => {
    console.log(newPagination);
    setData([]);
    setPagination(newPagination);
    const params = {
      ...{ limit: newPagination.pageSize, page: newPagination.current },
    };
    getData.run(params);
  };

  //首次加载
  useEffect(() => {
    const params = {
      ...{ limit: pagination.pageSize, page: pagination.current },
    };
    getData.run(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mgy-setting">
      <Breadcrumb
        items={[
          {
            title: "配置",
          },
          {
            title: "portal配置",
          },
        ]}
      />
      <div className="opt-bar">
        <Button
          className="addBtn"
          type="primary"
          onClick={() => {
            console.log("add");
            props.history.push("/Setting/AddPortalSetting");
          }}
        >
          添加Portal配置
        </Button>
      </div>
      <CTable
        loading={getData.loading}
        rowClassName={() => "editable-row"}
        components={components}
        columns={eidtColumns}
        size="middle"
        dataSource={data}
        onChange={handleTableChange}
        pagination={pagination}
        rowKey={(record) => record.model}
      />
    </div>
  );
}

export default PortalSetting;
