import React, { useEffect, useState } from "react";
import { Image } from "antd";

function ImageRender(props) {
  const { record } = props;
  const [items, setItems] = useState([]);
  const [defaultSrc, setDefaultSrc] = useState("");
  const [videoSrc, setVideoSrc] = useState("");

  useEffect(() => {
    if (record.type === 1) {
      setItems([record.resource.poster]);
      setDefaultSrc(record.resource.poster);
      setVideoSrc(record.resource.videoSrc);
    } else if(record.type === 4) {
      
    } else {
      const arr = [];
      const host = record.resource.poster.host;
      //手动上传
      if(host){
        record.resource.poster.uris.forEach((item) => {
          arr.push(host + item);
        });
      } else {
        //外部链接
        record.resource.poster.forEach((item) => {
          arr.push(item.posterLink);
        });
      }

      setItems(arr);
      setDefaultSrc(arr[0]);
    }
  }, [record.resource.poster, record.resource.videoSrc, record.type]);
  if (record.type === 1) {
    return (
      <Image
        width={80}
        preview={{
          imageRender: () => (
            <video muted width="320px" controls src={videoSrc} />
          ),
          toolbarRender: () => null,
        }}
        src={defaultSrc}
      />
    );
  }
  if(record.type === 4){
    return <div style={{marginLeft: 5}}>无预览图</div>
  }
  return (
    <Image.PreviewGroup items={items}>
      <Image width={80} src={defaultSrc} />
    </Image.PreviewGroup>
  );
}

export default ImageRender;
