import React, { useState, useEffect } from "react";
import { Switch } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import CTable from "@/components/CTable";
import ImageRender from "@/components/ImageRender/ImageRender";
import { getPortalList } from "@/api/portal";

function PortalSelect(props) {
  const { portalId, onChoose, curPortal } = props;
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const { loading, run } = useRequest(
    (params) => {
      return getPortalList(params);
    },
    {
      manual: true,
      onSuccess: (res, params) => {
        console.log("list onFinish", res);
        const result = res.data.data;
        setPagination({
          current: result.mate.page,
          total: result.mate.total,
          pageSize: result.mate.pageSize,
        });
        setData(result.list);
        if (portalId) {
          const item = result.list.find(
            (item) => item.portalId === Number(portalId)
          );
          console.log(item);
          onChoose(true, item);
        }
      },
      onError: (error) => {
        console.log("error", error);
      },
    }
  );

  const columns = [
    {
      title: "预览图",
      dataIndex: "pic",
      render: (_, record) => <ImageRender record={record} />,
    },
    {
      title: "名称",
      dataIndex: "name",
    },
    {
      title: "时间",
      dataIndex: "createTime",
    },
    // {
    //   title: "类型",
    //   dataIndex: "portalId",
    // },
    {
      title: "选择",
      render: (_, record) => (
        <>
          <Switch
            disabled={props.type}
            checked={
              props.curPortal?.portalId === record.portalId ||
              Number(props.portalId) === record.portalId
                ? true
                : false
            }
            checkedChildren={<CheckOutlined />}
            onChange={(v) => {
              props.onChoose(v, record);
            }}
          />
        </>
      ),
    },
  ];

  const handleTableChange = (newPagination) => {
    console.log(newPagination);
    setData([]);
    setPagination(newPagination);
    const params = {
      ...{ limit: newPagination.pageSize, page: newPagination.current },
    };
    run(params);
  };

  //首次加载
  useEffect(() => {
    if (props.type) {
      setData([curPortal]);
    } else {
      const params = {
        ...{ limit: pagination.pageSize, page: pagination.current },
      };
      run(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="portal-list">
      <CTable
        columns={columns}
        dataSource={data}
        loading={loading}
        size="small"
        sticky={true}
        onChange={handleTableChange}
        pagination={pagination}
        hidePagination={props.type}
        rowKey={(record) => record.portalId}
      />
    </div>
  );
}

export default PortalSelect;
